.Mobile {
    width: 100%;
    display: none;
    flex-direction: column;
    padding: 40px 10px;
}

.Mobile > ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    font-weight: 600;
    color: rgb(2,42,78);
    gap: 20px;
    text-align: left;
}

.Mobile > ul > li:last-of-type {
    background-color: rgb(192,44,40);
    border: 1px solid rgb(192,44,40);
    width: fit-content;
    margin: 0 auto;
    color: white;
    padding: 10px 20px;
    transition: all 0.3s ease-in-out;
    border-radius: 5px;
}

.Mobile > ul > li:last-of-type:hover {
    background-color: white;
    color: rgb(192,44,40);
}

.down {
    display: flex;
}

@media (min-width: 1024px) {
    .Mobile {
        width: 100%;
        display: none;
        flex-direction: column;
        padding: 40px 10px;
    }
    
    .Mobile > ul {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        font-weight: 600;
        color: rgb(2,42,78);
        gap: 20px;
        text-align: center;
    }
    
    .Mobile > ul > li:last-of-type {
        background-color: rgb(192,44,40);
        border: 1px solid rgb(192,44,40);
        width: fit-content;
        margin: 0 auto;
        color: white;
        padding: 10px 20px;
        transition: all 0.3s ease-in-out;
        border-radius: 5px;
    }
    
    .Mobile > ul > li:last-of-type:hover {
        background-color: white;
        color: rgb(192,44,40);
    }
    
    .down {
        display: none;
    }
}