.BottomNavbarContainer {
    width: 100%;
    background-color: rgb(2,42,78);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    gap: 350px;
}

.BottomNavbarContainerLeft {
    display: flex;
    align-items: center;
}

.BottomNavbarContainerLeft > ul {
    display: flex;
    align-items: center;
    list-style-type: none;
    color: white;
    gap: 20px;
    font-weight: bolder;
    cursor: pointer;
}


.BottomNavbarContainerLeft > ul > li:hover {
    color: rgb(249,179,28);
}


.BottomNavbarContainerRight {
    display: flex;
    align-items: center;
}

.BottomNavbarContainerRight > ul {
    display: flex;
    align-items: center;
    list-style-type: none;
    color: white;
    gap: 20px;
    font-weight: bolder;
}

.BottomNavbarContainerRight > ul > li, .BottomNavbarContainerLeft > ul > li {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: smaller;
    margin: auto 0;
}

.BottomNavbarContainerRight > ul > li:hover {
    color: rgb(249,179,28);
}


/* Styles for screens smaller than 576px (XS) */

@media (max-width: 1024px) {
    .BottomNavbarContainer {
        width: 100%;
        background-color: rgb(2,42,78);
        display: none;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
        gap: 400px;
    }
    
    .BottomNavbarContainerLeft {
        display: flex;
        align-items: center;
    }
    
    .BottomNavbarContainerLeft > ul {
        display: flex;
        align-items: center;
        list-style-type: none;
        color: white;
        gap: 20px;
        font-weight: bolder;
    }
    
    
    .BottomNavbarContainerRight {
        display: flex;
        align-items: center;
    }
    
    .BottomNavbarContainerRight > ul {
        display: flex;
        align-items: center;
        list-style-type: none;
        color: white;
        gap: 20px;
        font-weight: bolder;
    }
}

@media (min-width: 1025px) and (max-width: 1580px) {
    .BottomNavbarContainer {
        width: 100%;
        background-color: rgb(2,42,78);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
        gap: 10px !important;
    }
    
    .BottomNavbarContainerLeft {
        display: flex;
        align-items: center;
    }
    
    .BottomNavbarContainerLeft > ul {
        display: flex;
        align-items: center;
        list-style-type: none;
        color: white;
        gap: 20px;
        font-weight: bolder;
    }
    
    
    .BottomNavbarContainerRight {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .BottomNavbarContainerRight > ul {
        display: flex;
        align-items: center;
        list-style-type: none;
        color: white;
        gap: 20px;
        font-weight: bolder;
    }
}