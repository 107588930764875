/* Gallery.css */

.GalContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}


.GalContainer > h2 {
    margin: 100px auto 0 auto;
}

.gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 4%;
    width: 60%;
    margin: 100px auto;
  }
  
  /* Apply different heights for divs based on nth-of-type */
  .image:nth-child(odd) {
    grid-row: span 2;
    height: auto;
    position: relative;
  }
  
  
  .image:nth-child(even) {
    grid-row: span 3;
    height: auto;
  }
  
  .image:nth-child(3n) {
    grid-row: span 1;
    height: auto;
  }
  
  /* Add more styles for other nth-of-type divs as needed */
  
  .image {
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  


  /* Gallery.css */

/* ... Your existing gallery styles ... */

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
  }
  
  .pagination button {
    background-color: rgb(2,42,78);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    margin: 0 5px;
    cursor: pointer;
    outline: none;
  }
  
  .pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .pagination span {
    font-size: 18px;
    margin: 0 10px;
  }
  


  /* Media query for smaller screens */
  @media (max-width: 575.98px) {
    .gallery {
        width: 90%;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjusted to fit two columns */
        grid-gap: 3%; /* Adjusted gap between columns */
        grid-row-gap: 1%; /* Adjusted gap between rows */
        margin: 20px auto;
    }

    .image:nth-child(odd),
    .image:nth-child(3n) {
        grid-row: span 3; /* Adjusted to span 2 rows */
        height: auto;
    }
    
    .image:nth-child(even) {
        grid-row: span 3;
        height: auto;
    }

    .pagination button {
        padding: 8px 12px;
        margin: 0 3px;
    }

    .pagination span {
        font-size: 14px;
        margin: 0 8px;
    }
}


@media (min-width: 600px) and (max-width: 1024px) {
  .gallery {
    width: 90%;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjusted to fit two columns */
    grid-gap: 3%; /* Adjusted gap between columns */
    grid-row-gap: 1%; /* Adjusted gap between rows */
    margin: 20px auto;
  }

  .image:nth-child(odd),
  .image:nth-child(3n) {
    grid-row: span 3; /* Adjusted to span 2 rows */
    height: auto;
  }

  .image:nth-child(even) {
    grid-row: span 3;
    height: auto;
  }

  .pagination button {
    padding: 8px 12px;
    margin: 0 3px;
  }

  .pagination span {
    font-size: 14px;
    margin: 0 8px;
  }
}