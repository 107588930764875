.landingPageCommunity {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.landingPageCommunity > .one {
    width: 100%;
    height: 60vh;
    display: flex;
    position: relative;
}

.landingPageCommunity > .one > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.landingPageCommunity > .one > h1 {
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translateX(-50%);
    background-color: rgb(2,42,78);
    color: white;
    padding: 10px 20px;
}



.landingPageCommunity > .two  {
    width: 100%;
    display: flex;
    background-color: white;
    padding: 50px 0;
}

.landingPageCommunity > .two > div:nth-of-type(1)  {
    flex: 2;
    text-decoration: line-through;
    position: relative;
}

.landingPageCommunity > .two > div:nth-of-type(1)::before {
    content: '';
    position: absolute;
    top: 50%; /* Position it vertically at 50% of the parent div */
    left: 0; /* Position it at the left edge of the parent div */
    width: 100%; /* Make it extend the full width of the parent div */
    border-top: 1px solid rgba(211, 211, 211, .6); /* Color and style of the line */
}


.landingPageCommunity > .two > div:nth-of-type(2)  {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: start;
}

.landingPageCommunity > .two > div:nth-of-type(3)  {
    flex: 2;
    text-decoration: line-through;
    position: relative;
}

.landingPageCommunity > .two > div:nth-of-type(3) > h1 {
    font-size: 30px;
}

.landingPageCommunity > .two > div:nth-of-type(3)::before {
    content: '';
    position: absolute;
    top: 50%; /* Position it vertically at 50% of the parent div */
    left: 0; /* Position it at the left edge of the parent div */
    width: 100%; /* Make it extend the full width of the parent div */
    border-top: 1px solid rgba(211, 211, 211, .6); /* Color and style of the line */
}


.landingPageCommunity > .three  {
    width: 60%;
    display: flex;
    background-color: white;
    margin: 0px auto 50px auto;
    border: 4px solid rgb(12,46,78);
    padding: 40px;
    font-weight: bolder;
}


.mrRosaImage {
    width: 10%;
    margin: 0 auto 50px auto;
}

.mrRosaImage > img {
    width: 100%;
    height: auto;
    object-fit: cover;
}



@media (max-width: 1024px) {
    .landingPageCommunity {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 10px;
    }
    
    .landingPageCommunity > .one {
        width: 100%;
        min-height: 40vh;
        display: flex;
        position: relative;
    }
    
    .landingPageCommunity > .one > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .landingPageCommunity > .one > h1 {
        position: absolute;
        left: 50%;
        bottom: 20%;
        background-color: rgb(2,42,78);
        color: white;
        padding: 10px 20px;
        text-align: start;
    }
    
    
    
    .landingPageCommunity > .two  {
        width: 100%;
        display: flex;
        background-color: white;
        padding: 10px 0;
    }
    
    .landingPageCommunity > .two > div:nth-of-type(1)  {
        flex: 1;
        text-decoration: line-through;
        position: relative;
    }
    
    .landingPageCommunity > .two > div:nth-of-type(1)::before {
        content: '';
        position: absolute;
        top: 50%; /* Position it vertically at 50% of the parent div */
        left: 0; /* Position it at the left edge of the parent div */
        width: 100%; /* Make it extend the full width of the parent div */
        border-top: 1px solid rgba(169, 169, 169, 0.5); /* Color and style of the line */
    }
    
    
    .landingPageCommunity > .two > div:nth-of-type(2)  {
        flex: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: start;
    }
    
    .landingPageCommunity > .two > div:nth-of-type(3)  {
        flex: 1;
        text-decoration: line-through;
        position: relative;
    }
    
    .landingPageCommunity > .two > div:nth-of-type(3) > h1 {
        font-size: 30px;
    }
    
    .landingPageCommunity > .two > div:nth-of-type(3)::before {
        content: '';
        position: absolute;
        top: 50%; /* Position it vertically at 50% of the parent div */
        left: 0; /* Position it at the left edge of the parent div */
        width: 100%; /* Make it extend the full width of the parent div */
        border-top: 1px solid rgba(169, 169, 169, 0.5); /* Color and style of the line */
    }
    
    
    .landingPageCommunity > .three  {
        width: 90%;
        display: flex;
        text-align: center;
        background-color: white;
        padding: 10px 0;
        margin: 0px auto 10px auto;

    }


    .mrRosaImage {
        width: 40%;
        margin: 0 auto 10px auto;
    }
    
    .mrRosaImage > img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}