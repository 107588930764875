.landingNavbarContainer {
    background: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    position: relative;
    z-index: 2;
}



/* Styles for screens smaller than 576px (XS) */
@media (max-width: 575.98px) {
    .landingNavbarContainer {
        background: rgb(255, 255, 255);
        display: flex;
        flex-direction: column;
        overflow: hidden;
        width: 100%;
        position: relative;
    }

    .fixed {
        position: fixed;
        background-color: white;
        top: 0;
        left: 0;
      }
}


/* Tablets: screens with a width between 600px and 1024px */
@media (min-width: 600px) and (max-width: 1024px) {
    .landingNavbarContainer {
        background: rgb(255, 255, 255);
        display: flex;
        flex-direction: column;
        overflow: hidden;
        width: 100%;
        position: relative;
    }

    .fixed {
        position: fixed;
        background-color: white;
        top: 0;
        left: 0;
      }
}