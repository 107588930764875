.landingPageEducation {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.landingPageEducation > .one {
    width: 100%;
    height: 60vh;
    display: flex;
    position: relative;
}

.landingPageEducation > .one > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.landingPageEducation > .one > h1 {
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translateX(-50%);
    background-color: rgba(42,85,115, .6);
    color: white;
    padding: 10px 20px;
}



.landingPageEducation > .two  {
    width: 100%;
    display: flex;
    background-color: white;
    padding: 50px 0;

    /* background: url('../../images/map-deco-2.webp'); */
    background-repeat: no-repeat;
    background-position: center;
}

.landingPageEducation > .two > div:nth-of-type(1)  {
    flex: 2;
    text-decoration: line-through;
    position: relative;
}

.landingPageEducation > .two > div:nth-of-type(1)::before {
    content: '';
    position: absolute;
    top: 50%; /* Position it vertically at 50% of the parent div */
    left: 0; /* Position it at the left edge of the parent div */
    width: 100%; /* Make it extend the full width of the parent div */
    border-top: 1px solid lightgray; /* Color and style of the line */
}


.landingPageEducation > .two > div:nth-of-type(2)  {
    flex: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.landingPageEducation > .two > div:nth-of-type(3)  {
    flex: 2;
    text-decoration: line-through;
    position: relative;
}

.landingPageEducation > .two > div:nth-of-type(3) > h1 {
    font-size: 30px;
}

.landingPageEducation > .two > div:nth-of-type(3)::before {
    content: '';
    position: absolute;
    top: 50%; /* Position it vertically at 50% of the parent div */
    left: 0; /* Position it at the left edge of the parent div */
    width: 100%; /* Make it extend the full width of the parent div */
    border-top: 1x solid lightgray; /* Color and style of the line */
}


.landingPageEducation > .three  {
    width: 60%;
    display: flex;
    background-color: white;
    margin: 0px auto 10px auto;
    border: 4px solid rgb(12,46,78);
    padding: 40px;
    font-weight: bolder;
}

.landingPageEducation > .three  > h4 {
    font-weight: 500;
}





.landingPageEducation > .child {
    flex: 1;
    padding: 20px;
    display: flex;
    gap: 20px;
    padding: 0 20px 200px 20px;
    width: 70%;
    margin: auto;
}

.landingPageEducation > .child > button {
    background-color: rgb(0,100,159);
    color: white;
    transition: all 0.3s ease-in-out;
    text-transform: inherit;
    width: fit-content;
    margin-left: auto;
}


.landingPageEducation > .child > button:hover {
    background-color: rgb(255,182,44);
}

.landingPageEducation > .child > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background-color: rgb(249,250,251);
}



.landingPageEducation > .child > div:last-of-type {
    border-bottom: none;
}


.landingPageEducation > .child > div > div:nth-of-type(1) {
    flex: 1;
    display: flex;
    height: 20vh;
}

.landingPageEducation > .child > div > div:nth-of-type(1) > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.landingPageEducation > .child > div > div:nth-of-type(2) {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.landingPageEducation > .child > div > div:nth-of-type(2) > h4 {
    color: rgb(2,42,78);
}

.landingPageEducation > .child > div > div:nth-of-type(2) > h3:hover {
    text-decoration: underline;
    color: rgb(2,42,78);
    transition: all 0.3s ease-in-out;
}




@media (max-width: 1024px) {
    .landingPageEducation {
        display: flex;
        width: 100%;
        flex-direction: column;
    }
    
    .landingPageEducation > .one {
        width: 100%;
        height: 40vh;
        display: flex;
        position: relative;
    }
    
    .landingPageEducation > .one > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .landingPageEducation > .one > h1 {
        position: absolute;
        left: 50%;
        bottom: 20%;
        background-color: rgba(42,85,115, .6);
        color: white;
        padding: 10px 20px;
        text-align: center;
        
    }
    
    
    
    .landingPageEducation > .two  {
        width: 100%;
        display: flex;
        background-color: white;
        padding: 10px 0;
    }
    
    .landingPageEducation > .two > div:nth-of-type(1)  {
        flex: 1;
        text-decoration: line-through;
        position: relative;
    }
    
    .landingPageEducation > .two > div:nth-of-type(1)::before {
        content: '';
        position: absolute;
        top: 50%; /* Position it vertically at 50% of the parent div */
        left: 0; /* Position it at the left edge of the parent div */
        width: 100%; /* Make it extend the full width of the parent div */
        border-top: 1px solid lightgray; /* Color and style of the line */
    }
    
    
    .landingPageEducation > .two > div:nth-of-type(2)  {
        flex: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    
    .landingPageEducation > .two > div:nth-of-type(3)  {
        flex: 1;
        text-decoration: line-through;
        position: relative;
    }
    
    .landingPageEducation > .two > div:nth-of-type(3) > h1 {
        font-size: 30px;
    }
    
    .landingPageEducation > .two > div:nth-of-type(3)::before {
        content: '';
        position: absolute;
        top: 50%; /* Position it vertically at 50% of the parent div */
        left: 0; /* Position it at the left edge of the parent div */
        width: 100%; /* Make it extend the full width of the parent div */
        border-top: 1px solid lightgray; /* Color and style of the line */
    }
    
    
    .landingPageEducation > .three  {
        width: 90%;
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 10px;
        margin: 0px auto 10px auto;
        text-align: start;
    }
    
    
    
    
    
    .landingPageEducation > .child {
        flex: 1;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 0 10px 10px 10px;
        width: 90%;
        margin: auto;
    }
    
    .landingPageEducation > .child > button {
        background-color: rgb(0,100,159);
        color: white;
        transition: all 0.3s ease-in-out;
        text-transform: inherit;
        width: fit-content;
        margin-left: auto;
    }
    
    
    .landingPageEducation > .child > button:hover {
        background-color: rgb(255,182,44);
    }
    
    .landingPageEducation > .child > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        background-color: rgb(249,250,251);
    }
    
    
    
    .landingPageEducation > .child > div:last-of-type {
        border-bottom: none;
    }
    
    
    .landingPageEducation > .child > div > div:nth-of-type(1) {
        flex: 1;
        display: flex;
        height: 20vh;
    }
    
    .landingPageEducation > .child > div > div:nth-of-type(1) > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    
    .landingPageEducation > .child > div > div:nth-of-type(2) {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    
    
    .landingPageEducation > .child > div > div:nth-of-type(2) > h4 {
        color: rgb(2,42,78);
    }
    
    .landingPageEducation > .child > div > div:nth-of-type(2) > h3:hover {
        text-decoration: underline;
        color: rgb(2,42,78);
        transition: all 0.3s ease-in-out;
    }
}