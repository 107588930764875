.landingPageBanner {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 50px;
}


.landingPageBanner > .parent {
    width: 100%;
    padding: 10px 100px;
    display: flex;
    gap: 20px;
}

/* 4782859723 */
.landingPageBanner > .parent > .child:nth-of-type(1) {
    flex: 1;
    height: 53vh;
}

.landingPageBanner > .parent > .child:nth-of-type(1) > video {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.landingPageBanner > .parent > .child:nth-of-type(2) {
    flex: 1;
    display: flex;
    height: 55vh;
    overflow: hidden;
    padding-bottom: 10px;
    gap: 10px;
    position: relative;
    
}

.landingPageBanner > .parent > .child:nth-of-type(2) > button {
    background-color: rgb(2,42,78);
    color: white;
    transition: all 0.3s ease-in-out;
    text-transform: inherit;
    width: fit-content;
    margin-left: auto;
}

.landingPageBanner > .parent > .child:nth-of-type(2) > button:hover {
    background-color: rgb(255,182,44);
}

.landingPageBanner > .parent > .child:nth-of-type(2) > div {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* padding-top: 10px; */
    padding-bottom: 50px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}



.landingPageBanner > .parent > .child:nth-of-type(2) > div > div {
    flex: 1;
    display: flex;
    height: 15vh;
}

.landingPageBanner > .parent > .child:nth-of-type(2) > div > div > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}





.landingPageBanner > .parent > .child:nth-of-type(2) > div > div > h4 {
    color: rgb(0,58,112);
}

.landingPageBanner > .parent > .child:nth-of-type(2) > div > div > h3:hover {
    text-decoration: underline;
    color: rgb(0,58,112);
    transition: all 0.3s ease-in-out;
}


.landingPageBanner > .landingPageBannerDonateButton {
    border: 1px solid rgb(255,182,44);
    background-color: rgb(255,182,44);
    color: white;
    margin: 50px auto 0 auto;
    transition: all 0.3s ease-in-out;
}


.landingPageBanner > .landingPageBannerDonateButton:hover {
    background-color: white;
    color: rgb(255,182,44);
}


 .three {
    display: flex;
    width: 100%;
    background-color: rgb(235,235,235);
    padding: 50px 0px;
}


.three > div:nth-of-type(1)  {
    flex: 2;
    text-decoration: line-through;
    position: relative;
}

 .three > div:nth-of-type(1)::before {
    content: '';
    position: absolute;
    top: 50%; /* Position it vertically at 50% of the parent div */
    left: 0; /* Position it at the left edge of the parent div */
    width: 100%; /* Make it extend the full width of the parent div */
    border-top: 1px solid lightgray; /* Color and style of the line */
}


.three > div:nth-of-type(2)  {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.three > div:nth-of-type(3)  {
    flex: 2;
    text-decoration: line-through;
    position: relative;
}

 .three > div:nth-of-type(3) > h1 {
    font-size: 30px;
}

 .three > div:nth-of-type(3)::before {
    content: '';
    position: absolute;
    top: 50%; /* Position it vertically at 50% of the parent div */
    left: 0; /* Position it at the left edge of the parent div */
    width: 100%; /* Make it extend the full width of the parent div */
    border-top: 1px solid lightgray; /* Color and style of the line */
}


@media (max-width: 575.98px) {
     .three {
        display: flex;
        width: 100%;
        background-color: rgb(235,235,235);
        padding: 50px 0px;
    }
    
    .three > div:nth-of-type(1)  {
        flex: 1;
        text-decoration: line-through;
        position: relative;
    }
    
    .three > div:nth-of-type(1)::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        border-top: 1px solid lightgray;
    }
    
    .three > div:nth-of-type(2)  {
        flex: 4;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
   .three > div:nth-of-type(3)  {
        flex: 1;
        text-decoration: line-through;
        position: relative;
    }
    
     .three > div:nth-of-type(3) > h1 {
        font-size: 30px;
    }
    
    .three > div:nth-of-type(3)::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        border-top: 1px solid lightgray;
    }
    .landingPageBanner {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px;
    }
    
    
    .landingPageBanner > .parent {
        width: 100%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        /* gap: 20px; */
        
    }
    
    .landingPageBanner > .parent > .child:nth-of-type(1) {
        flex: 2;
        height: 70vh;
    }
    
    .landingPageBanner > .parent > .child:nth-of-type(1) > video {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
    
    .landingPageBanner > .parent > .child:nth-of-type(2) {
        /* flex: 1; */
        
        /* padding: 20px; */
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-left: 0px solid lightgray;
    }
    
    .landingPageBanner > .parent > .child:nth-of-type(2) > button {
        background-color: rgb(2,42,78);
        color: white;
        transition: all 0.3s ease-in-out;
        text-transform: inherit;
        width: fit-content;
        margin-left: auto;
    }
    
    .landingPageBanner > .parent > .child:nth-of-type(2) > button:hover {
        background-color: rgb(255,182,44);
    }
    
    .landingPageBanner > .parent > .child:nth-of-type(2) > div {
        flex: 1;
        display: flex;
        gap: 20px;
        /* padding-top: 10px;*/
        /* padding-bottom: 500px;  */
        cursor: pointer;
        transition: all 0.3s ease-in-out;
       
    }

    
    
    
    .landingPageBanner > .parent > .child:nth-of-type(2) > div > div {
        flex: 1;
        display: flex;
        height: 15vh;
       
    }
    
    .landingPageBanner > .parent > .child:nth-of-type(2) > div > div > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    
    .landingPageBanner > .parent > .child:nth-of-type(2) > div > div {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 10px;
        
       
    }
    
    
    .landingPageBanner > .parent > .child:nth-of-type(2) > div > div > h4 {
        color: rgb(0,58,112);
    }
    
    .landingPageBanner > .parent > .child:nth-of-type(2) > div > div > h3:hover {
        text-decoration: underline;
        color: rgb(0,58,112);
        transition: all 0.3s ease-in-out;
    }
}



/* Tablets: screens with a width between 600px and 1024px */
@media (min-width: 600px) and (max-width: 1024px) {
    /* CSS styles for tablets go here */

    .landingPageBanner {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    
    
    .landingPageBanner > .parent {
        width: 100%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    
    .landingPageBanner > .parent > .child:nth-of-type(1) {
        flex: 2;
        height: 70vh;
    }
    
    .landingPageBanner > .parent > .child:nth-of-type(1) > video {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
    
    .landingPageBanner > .parent > .child:nth-of-type(2) {
        flex: 1;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-left: 0px solid lightgray;
    }
    
    .landingPageBanner > .parent > .child:nth-of-type(2) > button {
        background-color: rgb(2,42,78);
        color: white;
        transition: all 0.3s ease-in-out;
        text-transform: inherit;
        width: fit-content;
        margin-left: auto;
    }
    
    .landingPageBanner > .parent > .child:nth-of-type(2) > button:hover {
        background-color: rgb(255,182,44);
    }
    
    .landingPageBanner > .parent > .child:nth-of-type(2) > div {
        flex: 1;
        display: flex;
        gap: 20px;
        padding-top: 10px;
        padding-bottom: 50px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }

    
    
    .landingPageBanner > .parent > .child:nth-of-type(2) > div > div {
        flex: 1;
        display: flex;
        height: 15vh;
    }
    
    .landingPageBanner > .parent > .child:nth-of-type(2) > div > div > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    
    .landingPageBanner > .parent > .child:nth-of-type(2) > div > div {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    
    
    .landingPageBanner > .parent > .child:nth-of-type(2) > div > div > h4 {
        color: rgb(0,58,112);
    }
    
    .landingPageBanner > .parent > .child:nth-of-type(2) > div > div > h3:hover {
        text-decoration: underline;
        color: rgb(0,58,112);
        transition: all 0.3s ease-in-out;
    }
}