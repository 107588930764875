.landingPageResource {
    width: 100%;
    display: flex;
    flex-direction: column;
}


.landingPageResource > .one {
    width: 100%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: start;
    gap: 20px;
    padding: 100px;
    background-color: white;
}

.landingPageResource > .one > h2 {
    color: rgb(12,46,78);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    border: 4px solid rgb(12,46,78);
    padding: 50px;
}


.landingPageResource > .two {
    width: 100%;
    background-color: rgba(2,42,78, .9);
    color: white;
    display: flex;
    padding: 50px 100px;
    justify-content: space-between;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.landingPageResource > .two > div:nth-of-type(1) {
    margin: 0 auto;
    width: 80%;
    display: flex;
    justify-content: space-between;
}

.landingPageResource > .two > div:nth-of-type(1) > div:nth-of-type(1) {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
}

.landingPageResource > .two > div:nth-of-type(1) > div:nth-of-type(1) > h3 {
    font-size: 30px;
    text-align: center;
}

.landingPageResource > .two > div:nth-of-type(1) > div:nth-of-type(2) {
    display: flex;
    align-items: center;
    justify-content: center;
}

.landingPageResource > .two > div:nth-of-type(1) > div:nth-of-type(2) > button {
    border: 1px solid white;
    background-color: transparent;
    columns: rgba(2,42,78, .9);
    color: white;
}



.landingPageResource > .three {
    display: flex;
    width: 100%;
    background-color: rgb(235,235,235);
    padding: 50px 0px;
}


.landingPageResource > .three > div:nth-of-type(1)  {
    flex: 2;
    text-decoration: line-through;
    position: relative;
}

.landingPageResource > .three > div:nth-of-type(1)::before {
    content: '';
    position: absolute;
    top: 50%; /* Position it vertically at 50% of the parent div */
    left: 0; /* Position it at the left edge of the parent div */
    width: 100%; /* Make it extend the full width of the parent div */
    border-top: 1px solid lightgray; /* Color and style of the line */
}


.landingPageResource > .three > div:nth-of-type(2)  {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landingPageResource > .three > div:nth-of-type(3)  {
    flex: 2;
    text-decoration: line-through;
    position: relative;
}

.landingPageResource > .three > div:nth-of-type(3) > h1 {
    font-size: 30px;
}

.landingPageResource > .three > div:nth-of-type(3)::before {
    content: '';
    position: absolute;
    top: 50%; /* Position it vertically at 50% of the parent div */
    left: 0; /* Position it at the left edge of the parent div */
    width: 100%; /* Make it extend the full width of the parent div */
    border-top: 1px solid lightgray; /* Color and style of the line */
}



.landingPageResource > .ameenah {
    display: flex;
    width: 100%;
    background-color: rgb(235,235,235);
    padding: 50px 0px;
}


.landingPageResource > .ameenah > div:nth-of-type(1)  {
    flex: 2;
    text-decoration: line-through;
    position: relative;
}

.landingPageResource > .ameenah > div:nth-of-type(1)::before {
    content: '';
    position: absolute;
    top: 50%; /* Position it vertically at 50% of the parent div */
    left: 0; /* Position it at the left edge of the parent div */
    width: 100%; /* Make it extend the full width of the parent div */
    border-top: 1px solid lightgray; /* Color and style of the line */
}


.landingPageResource > .ameenah > div:nth-of-type(2)  {
    flex: 4;
    display: flex;
    align-items: center;
    justify-content: center;
}


.landingPageResource > .ameenah > div:nth-of-type(3)  {
    flex: 2;
    text-decoration: line-through;
    position: relative;
}

.landingPageResource > .ameenah > div:nth-of-type(3) > h1 {
    font-size: 30px;
}

.landingPageResource > .ameenah > div:nth-of-type(3)::before {
    content: '';
    position: absolute;
    top: 50%; /* Position it vertically at 50% of the parent div */
    left: 0; /* Position it at the left edge of the parent div */
    width: 100%; /* Make it extend the full width of the parent div */
    border-top: 1px solid lightgray; /* Color and style of the line */
}

.ameenah-intro-text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0 0 0;
}

.ameenah-intro-text > p > span {
    color: rgb(255,182,44);
    font-size: larger;
}


.landingPageResource > .four  {
    width: 100%;
    min-height: 80vh;
    display: flex;
    background: url('../../images/Jan\ 6th\ Riot.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.landingPageResource > .four  > div {
    width: 100%;
    margin: 0px;
    display: flex;
    flex-direction: column;
    background-color: rgba(2,42,78, .9);
}

.landingPageResource > .four  > div > div {
    width: 80%;
    margin: 20px auto;
    color: white;
    padding: 50px;
    flex-direction: column;
}

.landingPageResource > .four  > div > div > div:nth-of-type(1) {
    border-top: 1px solid rgba(255,255,255,.6);
    border-bottom: 1px solid rgba(255,255,255,.6);
    margin-bottom: 50px;
    display: flex;
    gap: 5%;
    padding: 20px;
    position: relative;
    cursor: pointer;
}

/* Create a pseudo-element for the border animation */
.landingPageResource > .four  > div > div > div:nth-of-type(1)::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border: 0px solid yellow;
    box-sizing: border-box;
    transition: all 0.5s;
}

/* Define the hover effect to animate the border */
.landingPageResource > .four  > div > div > div:nth-of-type(1):hover::before {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 2px solid yellow;
}


.landingPageResource > .four  > div > div > div:nth-of-type(1) > div {
    width: 45%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    gap: 10px;
}

.landingPageResource > .four  > div > div > div:nth-of-type(1) > div:nth-of-type(2) {
    border-left: 1px dotted rgba(255,255,255, .6);
}

.landingPageResource > .four  > div > div > div:nth-of-type(2) {
    display: flex;
    gap: 5%;
}


.landingPageResource > .four  > div > div > div:nth-of-type(2) > div {
    width: 30%;
    border-left: 1px solid rgba(255,255,255, .6);
    display: flex;
    gap: 10px;
    position: relative;
    padding: 0px 10px;
    cursor: pointer;
}


.landingPageResource > .four  > div > div > div:nth-of-type(2) > div::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border: 0px solid yellow;
    box-sizing: border-box;
    transition: all 0.5s;
}

/* Define the hover effect to animate the border */
.landingPageResource > .four  > div > div > div:nth-of-type(2) > div:hover::before {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 2px solid yellow;
}




.landingPageResource > .four  > div > div > div:nth-of-type(2) > div > div:nth-of-type(1) {
    background-color: white;
    padding: 10px;
    border-radius: 2px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}


.landingPageResource > .four  > div > div > div:nth-of-type(2) > div > div:nth-of-type(2) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.landingPageResource > .four  > div > .eventButtonClass {
    margin: 0 auto 100px auto;
    background-color: rgba(2,42,78, .9);
    padding: 10px 20px;
    color: white;
    border: 1px solid rgba(2,42,78, .9);
    transition: all 0.3s ease-in-out;
    text-transform: inherit;
}

.landingPageResource > .four  > div > .eventButtonClass:hover {
    background-color: white;
    color: rgba(2,42,78, .9);
}


.landingPageResource > .five  {
    width: 100%;
    display: flex;
    background-color: white;
    padding: 50px 0;
}

.landingPageResource > .five > div:nth-of-type(1)  {
    flex: 2;
    text-decoration: line-through;
    position: relative;
}

.landingPageResource > .five > div:nth-of-type(1)::before {
    content: '';
    position: absolute;
    top: 50%; /* Position it vertically at 50% of the parent div */
    left: 0; /* Position it at the left edge of the parent div */
    width: 100%; /* Make it extend the full width of the parent div */
    border-top: 1px solid lightgray; /* Color and style of the line */
}


.landingPageResource > .five > div:nth-of-type(2)  {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: start;
}

.landingPageResource > .five > div:nth-of-type(3)  {
    flex: 2;
    text-decoration: line-through;
    position: relative;
}

.landingPageResource > .five > div:nth-of-type(3) > h1 {
    font-size: 30px;
}

.landingPageResource > .five > div:nth-of-type(3)::before {
    content: '';
    position: absolute;
    top: 50%; /* Position it vertically at 50% of the parent div */
    left: 0; /* Position it at the left edge of the parent div */
    width: 100%; /* Make it extend the full width of the parent div */
    border-top: 1px solid lightgray; /* Color and style of the line */
}


.landingPageResource > .six  {
    width: 100%;
    display: flex;
    gap: 50px;
    background-color: rgb(242,242,242);
    padding: 0 100px 100px 100px;
    align-items: center;
    justify-content: center;
}


.landingPageResource > .six  > div {
   flex: 1;
   width: 100%;
   background-color: white;
   display: flex;
   flex-direction: column;
   padding: 20px;
   gap: 20px;
}

.landingPageResource > .six  > div > div:nth-of-type(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.landingPageResource > .six  > div > button {
    background-color: rgba(2,42,78, .9);
    color: white;
    width: fit-content;
    padding: 10px 20px;
    text-transform: inherit;
    transition: all 0.3s ease-in-out;
    border: 1px solid rgba(2,42,78, .9);
}

.landingPageResource > .six  > div > button:hover {
    background-color: white;
    color: rgba(2,42,78, .9);
}

.landingPageResource > .six  > div > div:nth-of-type(1) > p:nth-of-type(2) {
    border-bottom: 1px solid rgba(0,0,0, .5);
}

.landingPageResource > .six > div > div:nth-of-type(2) {
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(228, 224, 224, .4);
    padding: 20px 10px;
    overflow-x: auto;
    gap: 5%;
}

.landingPageResource > .six > div > div:nth-of-type(2)::-webkit-scrollbar {
    display: none;
}

.landingPageResource > .six > div > div:nth-of-type(2) {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}


.landingPageResource > .six > div > div:nth-of-type(2) > div {
    flex: 1;
    min-width: 30%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
}


.landingPageResource > .six > div > div:nth-of-type(2) > div > img {
    width: 100%;
    height: 20vh;
    object-fit: cover;
}



.seven {
    width: 100%;
    padding: 100px;
    display: flex;
    gap: 4%;
    background-color: rgb(242,242,242);
}

.seven > div {
    width: 48%;
    display: flex;
    flex-direction: column;
    background-color: white;
    gap: 20px;
}

.seven > div > img {
    width: 100%;
    height: 40vh;
    object-fit: cover;
}

.seven > div > p:nth-of-type(1) {
    margin: 0 20px;
    text-decoration: underline;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: bolder;
    transition: all 0.3s ease-in-out;
    width: fit-content;
}

.seven > div > p:nth-of-type(1):hover {
    color: rgb(255,182,44);
}


.seven > div > p:nth-of-type(2) {
    margin: 0 20px 20px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}



.objective  {
    width: 80%;
    background-color: rgb(235,235,235);
    display: flex;
    gap: 50px;
    padding: 50px 100px;
    margin: 0 auto;
}

.objective > .objectiveLeft,
.objective > .objectiveRight {
    background-color: white;
    flex: 1;
    padding: 20px;
}

.objective  > div > ul {
    padding: 20px;
}

.actionNetwork {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}



@media (max-width: 575.98px) {
    .landingPageResource {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    
    .landingPageResource > .one {
        width: 100%;
        margin: 0px auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      text-align: start;
        gap: 20px;
        padding: 20px 10px;
    }
    

    
    .landingPageResource > .two {
        width: 100%;
        background-color: rgba(2,42,78, .9);
        color: white;
        display: flex;
        padding: 10px;
        justify-content: space-between;
        min-height: 20vh;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }
    
    .landingPageResource > .two > div:nth-of-type(1) {
        margin: 0 auto;
        width: 90%;
        display: flex;
        justify-content: space-between;
    }
    
    .landingPageResource > .two > div:nth-of-type(1) > div:nth-of-type(1) {
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
    }
    
    .landingPageResource > .two > div:nth-of-type(1) > div:nth-of-type(1) > h3 {
        font-size: 20px;
    }
    
    .landingPageResource > .two > div:nth-of-type(1) > div:nth-of-type(2) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .landingPageResource > .two > div:nth-of-type(1) > div:nth-of-type(2) > button {
        border: 1px solid white;
        background-color: transparent;
        columns: rgba(2,42,78, .9);
        color: white;
    }
    
    .landingPageResource > .two:hover > div:nth-of-type(1) > div:nth-of-type(1) > p {
        display: none;
    }
    
    .landingPageResource > .three {
        display: flex;
        width: 100%;
        background-color: rgb(235,235,235);
        padding: 10px 0px;
    }
    
    .landingPageResource > .three > div:nth-of-type(1)  {
        flex: 1;
        text-decoration: line-through;
        position: relative;
    }
    
    .landingPageResource > .three > div:nth-of-type(1)::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        border-top: 1px solid lightgray;
    }
    
    .landingPageResource > .three > div:nth-of-type(2)  {
        flex: 4;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .landingPageResource > .three > div:nth-of-type(3)  {
        flex: 1;
        text-decoration: line-through;
        position: relative;
    }
    
    .landingPageResource > .three > div:nth-of-type(3) > h1 {
        font-size: 30px;
    }
    
    .landingPageResource > .three > div:nth-of-type(3)::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        border-top: 1px solid lightgray;
    }


    .landingPageResource > .ameenah {
        display: flex;
        width: 100%;
        background-color: rgb(235,235,235);
        padding: 10px 0px;
    }
    
    
    .landingPageResource > .ameenah > div:nth-of-type(1)  {
        flex: 1;
        text-decoration: line-through;
        position: relative;
    }
    
    .landingPageResource > .ameenah > div:nth-of-type(1)::before {
        content: '';
        position: absolute;
        top: 50%; /* Position it vertically at 50% of the parent div */
        left: 0; /* Position it at the left edge of the parent div */
        width: 100%; /* Make it extend the full width of the parent div */
        border-top: 1px solid lightgray; /* Color and style of the line */
    }
    
    
    .landingPageResource > .ameenah > div:nth-of-type(2)  {
        flex: 6;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: x-small;
    }
    
    .landingPageResource > .ameenah > div:nth-of-type(3)  {
        flex: 1;
        text-decoration: line-through;
        position: relative;
    }
    
    .landingPageResource > .ameenah > div:nth-of-type(3) > h1 {
        font-size: 30px;
    }
    
    .landingPageResource > .ameenah > div:nth-of-type(3)::before {
        content: '';
        position: absolute;
        top: 50%; /* Position it vertically at 50% of the parent div */
        left: 0; /* Position it at the left edge of the parent div */
        width: 100%; /* Make it extend the full width of the parent div */
        border-top: 1px solid lightgray; /* Color and style of the line */
    }

    .ameenah-intro-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px 10px 0 10px;
    }

    
    .landingPageResource > .four  {
        width: 100%;
        min-height: 80vh;
        display: flex;
        background: url('../../images/Jan\ 6th\ Riot.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    
    .landingPageResource > .four  > div {
        width: 100%;
        margin: 0px;
        display: flex;
        flex-direction: column;
        background-color: rgba(2,42,78, .9);
    }
    
    .landingPageResource > .four  > div > div {
        width: 100%;
        margin: 100px auto;
        color: white;
        padding: 10px;
        flex-direction: column;
    }
    
    .landingPageResource > .four  > div > div > div:nth-of-type(1) {
        border-top: 1px solid rgba(255,255,255,.6);
        border-bottom: 1px solid rgba(255,255,255,.6);
        margin-bottom: 50px;
        display: flex;
        gap: 5%;
        padding: 20px;
    }
    
    .landingPageResource > .four  > div > div > div:nth-of-type(1) > div {
        width: 45%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 20px;
        gap: 10px;
    }
    
    .landingPageResource > .four  > div > div > div:nth-of-type(1) > div:nth-of-type(2) {
        border-left: 1px dotted rgba(255,255,255, .6);
    }
    
    .landingPageResource > .four  > div > div > div:nth-of-type(2) {
        display: flex;
        flex-wrap: wrap;
        gap: 5%;
    }
    
    
    .landingPageResource > .four  > div > div > div:nth-of-type(2) > div {
        width: 90%;
        border-left: 0px solid rgba(255,255,255, .6);
        display: flex;
        gap: 10px;
        padding: 0px 10px;
        margin-bottom: 20px;
    }
    
    .landingPageResource > .four  > div > div > div:nth-of-type(2) > div > div:nth-of-type(1) {
        background-color: white;
        padding: 10px;
        border-radius: 2px;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    
    .landingPageResource > .four  > div > div > div:nth-of-type(2) > div > div:nth-of-type(2) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
    }
    
    .landingPageResource > .four  > div > .eventButtonClass {
        margin: 0 auto 100px auto;
        background-color: rgba(2,42,78, .9);
        padding: 10px 20px;
        color: white;
        border: 1px solid rgba(2,42,78, .9);
        transition: all 0.3s ease-in-out;
        text-transform: inherit;
    }
    
    .landingPageResource > .four  > div > .eventButtonClass:hover {
        background-color: white;
        color: rgba(2,42,78, .9);
    }
    
    .landingPageResource > .five  {
        width: 100%;
        display: flex;
        background-color: white;
        padding: 10px 0;
    }
    
    .landingPageResource > .five > div:nth-of-type(1)  {
        flex: 1;
        text-decoration: line-through;
        position: relative;
    }
    
    .landingPageResource > .five > div:nth-of-type(1)::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        border-top: 1px solid lightgray;
    }
    
    .landingPageResource > .five > div:nth-of-type(2)  {
        flex: 5;
        display: flex;
        align-items: center;
        justify-content: center;
      text-align: start;
    }
    
    .landingPageResource > .five > div:nth-of-type(3)  {
        flex: 1;
        text-decoration: line-through;
        position: relative;
    }
    
    .landingPageResource > .five > div:nth-of-type(3) > h1 {
        font-size: 30px;
    }
    
    .landingPageResource > .five > div:nth-of-type(3)::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        border-top: 1px solid lightgray;
    }
    
    .landingPageResource > .six  {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        background-color: white;
        padding: 20px 0;
        align-items: center;
        justify-content: center;
      text-align: start;
    }

    .landingPageResource > .six  > div > div:nth-of-type(1) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }


    .landingPageResource > .six > div > div:nth-of-type(2) > div {
        flex: 1;
        min-width: 48%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        cursor: pointer;
    }
    
    .landingPageResource > .six  > button {
        background-color: rgb(245,143,30);
        color: white;
        transition: all 0.3s ease-in-out;
        margin: 20px auto;
    }
    
    .landingPageResource > .six  > button:hover {
        background-color: rgb(159,0,6);
    }



    .seven {
        width: 100%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        background-color: rgb(242,242,242);
    }
    
    .seven > div {
        width: 98%;
        display: flex;
        flex-direction: column;
        background-color: white;
        gap: 20px;
        margin: 0 auto;
    }
    
    .seven > div > img {
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: center;
    }
    
    .seven > div > p:nth-of-type(1) {
        margin: 0 20px;
        text-decoration: underline;
        padding-bottom: 5px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    
    
    .seven > div > p:nth-of-type(2) {
        margin: 0 20px 20px 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }



    .objective  {
        width: 100%;
        background-color: rgb(235,235,235);
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
        margin: 0 auto;
    }
    
    .objective > .objectiveLeft,
    .objective > .objectiveRight {
        background-color: white;
        flex: 1;
        padding: 10px;
    }
    
    .objective  > div > ul {
        padding: 20px;
    }
}


/* Tablets: screens with a width between 600px and 1024px */
@media (min-width: 600px) and (max-width: 1024px) {
    /* CSS styles for tablets go here */

    .landingPageResource {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    
    .landingPageResource > .one {
        width: 100%;
        margin: 0px auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      text-align: start;
        gap: 20px;
        padding: 10px;
    }
    
    
    .landingPageResource > .two {
        width: 100%;
        background-color: rgba(2,42,78, .9);
        color: white;
        display: flex;
        padding: 10px;
        justify-content: space-between;
        min-height: 20vh;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }
    
    .landingPageResource > .two > div:nth-of-type(1) {
        margin: 0 auto;
        width: 90%;
        display: flex;
        justify-content: space-between;
    }
    
    .landingPageResource > .two > div:nth-of-type(1) > div:nth-of-type(1) {
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
    }
    
    .landingPageResource > .two > div:nth-of-type(1) > div:nth-of-type(1) > h3 {
        font-size: 20px;
    }
    
    .landingPageResource > .two > div:nth-of-type(1) > div:nth-of-type(2) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .landingPageResource > .two > div:nth-of-type(1) > div:nth-of-type(2) > button {
        border: 1px solid white;
        background-color: transparent;
        columns: rgba(2,42,78, .9);
        color: white;
    }
    
    .landingPageResource > .two:hover > div:nth-of-type(1) > div:nth-of-type(1) > p {
        display: none;
    }
    
    .landingPageResource > .three {
        display: flex;
        width: 100%;
        background-color: rgb(235,235,235);
        padding: 50px 0px;
    }
    
    .landingPageResource > .three > div:nth-of-type(1)  {
        flex: 1;
        text-decoration: line-through;
        position: relative;
    }
    
    .landingPageResource > .three > div:nth-of-type(1)::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        border-top: 1px solid lightgray;
    }
    
    .landingPageResource > .three > div:nth-of-type(2)  {
        flex: 4;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .landingPageResource > .three > div:nth-of-type(3)  {
        flex: 1;
        text-decoration: line-through;
        position: relative;
    }
    
    .landingPageResource > .three > div:nth-of-type(3) > h1 {
        font-size: 30px;
    }
    
    .landingPageResource > .three > div:nth-of-type(3)::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        border-top: 1px solid lightgray;
    }



    .landingPageResource > .ameenah {
        display: flex;
        width: 100%;
        background-color: rgb(235,235,235);
        padding: 50px 0px;
    }
    
    
    .landingPageResource > .ameenah > div:nth-of-type(1)  {
        flex: 1;
        text-decoration: line-through;
        position: relative;
    }
    
    .landingPageResource > .ameenah > div:nth-of-type(1)::before {
        content: '';
        position: absolute;
        top: 50%; /* Position it vertically at 50% of the parent div */
        left: 0; /* Position it at the left edge of the parent div */
        width: 100%; /* Make it extend the full width of the parent div */
        border-top: 1px solid lightgray; /* Color and style of the line */
    }
    
    
    .landingPageResource > .ameenah > div:nth-of-type(2)  {
        flex: 4;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: smaller;
    }
    
    .landingPageResource > .ameenah > div:nth-of-type(3)  {
        flex: 1;
        text-decoration: line-through;
        position: relative;
    }
    
    .landingPageResource > .ameenah > div:nth-of-type(3) > h1 {
        font-size: 30px;
    }
    
    .landingPageResource > .ameenah > div:nth-of-type(3)::before {
        content: '';
        position: absolute;
        top: 50%; /* Position it vertically at 50% of the parent div */
        left: 0; /* Position it at the left edge of the parent div */
        width: 100%; /* Make it extend the full width of the parent div */
        border-top: 1px solid lightgray; /* Color and style of the line */
    }


    .ameenah-intro-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 20px 0 20px;
    }

    
    .landingPageResource > .four  {
        width: 100%;
        min-height: 20vh;
        display: flex;
        background: url('../../images/Jan\ 6th\ Riot.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    
    .landingPageResource > .four  > div {
        width: 100%;
        margin: 0px;
        display: flex;
        flex-direction: column;
        background-color: rgba(2,42,78, .9);
    }
    
    .landingPageResource > .four  > div > div {
        width: 100%;
        margin: 100px auto;
        color: white;
        padding: 10px;
        flex-direction: column;
    }
    
    .landingPageResource > .four  > div > div > div:nth-of-type(1) {
        border-top: 1px solid rgba(255,255,255,.6);
        border-bottom: 1px solid rgba(255,255,255,.6);
        margin-bottom: 50px;
        display: flex;
        gap: 5%;
        padding: 20px;
    }
    
    .landingPageResource > .four  > div > div > div:nth-of-type(1) > div {
        width: 45%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 20px;
        gap: 10px;
    }
    
    .landingPageResource > .four  > div > div > div:nth-of-type(1) > div:nth-of-type(2) {
        border-left: 1px dotted rgba(255,255,255, .6);
    }
    
    .landingPageResource > .four  > div > div > div:nth-of-type(2) {
        display: flex;
        flex-wrap: wrap;
        gap: 5%;
    }
    
    
    .landingPageResource > .four  > div > div > div:nth-of-type(2) > div {
        width: 90%;
        border-left: 0px solid rgba(255,255,255, .6);
        display: flex;
        gap: 10px;
        padding: 0px 10px;
        margin-bottom: 40px;
        margin-left: auto;
        margin-right: auto;
    }
    
    .landingPageResource > .four  > div > div > div:nth-of-type(2) > div > div:nth-of-type(1) {
        background-color: white;
        padding: 10px;
        border-radius: 2px;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
    }
    
    
    .landingPageResource > .four  > div > div > div:nth-of-type(2) > div > div:nth-of-type(2) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
    }
    
    .landingPageResource > .four  > div > .eventButtonClass {
        margin: 0 auto 100px auto;
        background-color: rgba(2,42,78, .9);
        padding: 10px 20px;
        color: white;
        border: 1px solid rgba(2,42,78, .9);
        transition: all 0.3s ease-in-out;
        text-transform: inherit;
    }
    
    .landingPageResource > .four  > div > .eventButtonClass:hover {
        background-color: white;
        color: rgba(2,42,78, .9);
    }
    
    .landingPageResource > .five  {
        width: 100%;
        display: flex;
        background-color: white;
        padding: 50px 0;
    }
    
    .landingPageResource > .five > div:nth-of-type(1)  {
        flex: 1;
        text-decoration: line-through;
        position: relative;
    }
    
    .landingPageResource > .five > div:nth-of-type(1)::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        border-top: 1px solid lightgray;
    }
    
    .landingPageResource > .five > div:nth-of-type(2)  {
        flex: 5;
        display: flex;
        align-items: center;
        justify-content: center;
      text-align: start;
    }
    
    .landingPageResource > .five > div:nth-of-type(3)  {
        flex: 1;
        text-decoration: line-through;
        position: relative;
    }
    
    .landingPageResource > .five > div:nth-of-type(3) > h1 {
        font-size: 30px;
    }
    
    .landingPageResource > .five > div:nth-of-type(3)::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        border-top: 1px solid lightgray;
    }
    
    .landingPageResource > .six  {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        background-color: white;
        padding: 20px 0;
        align-items: center;
        justify-content: center;
      text-align: start;
    }

    .landingPageResource > .six  > div > div:nth-of-type(1) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }


    .landingPageResource > .six > div > div:nth-of-type(2) > div {
        flex: 1;
        min-width: 48%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        cursor: pointer;
    }
    
    .landingPageResource > .six  > button {
        background-color: rgb(245,143,30);
        color: white;
        transition: all 0.3s ease-in-out;
        margin: 20px auto;
    }
    
    .landingPageResource > .six  > button:hover {
        background-color: rgb(159,0,6);
    }


    .seven {
        width: 100%;
        padding: 20px;
        display: flex;
        gap: 4%;
        background-color: rgb(242,242,242);
    }
    
    .seven > div {
        width: 48%;
        display: flex;
        flex-direction: column;
        background-color: white;
        gap: 20px;
        margin: 0 auto;
    }
    
    .seven > div > img {
        width: 100%;
        height: auto;
        object-fit: 30vh;
        object-position: center;
    }
    
    .seven > div > p:nth-of-type(1) {
        margin: 0 20px;
        text-decoration: underline;
        padding-bottom: 5px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    
    
    .seven > div > p:nth-of-type(2) {
        margin: 0 20px 20px 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
}
