.landingPageLogin {
    width: 100%;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    background: url('../../images/log.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}


.landingPageLogin > form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 50px 20px;
    margin: auto;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.landingPageLogin > form > input {
    width: 100%;
    padding: 10px;
    outline: none;
    border: 5px;
    border: 1px solid lightgray;
}

.landingPageLogin > form > button {
    text-transform: inherit;
    background-color: rgb(2,42,78);
    color: white;
    transition: all 0.3s ease-in-out;
    width: fit-content;
    margin-left: auto;
}

.landingPageLogin > form > button:hover {
    background-color: rgb(248,201,24);
}



@media (max-width: 575.98px) {
    .landingPageLogin {
        width: 100%;
        min-height: 80vh;
        display: flex;
        flex-direction: column;
        background: url('../../images/log.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    
    
    .landingPageLogin > form {
        width: 90%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 50px 20px;
        margin: auto;
        background-color: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    }
    
    .landingPageLogin > form > input {
        width: 100%;
        padding: 10px;
        outline: none;
        border: 5px;
        border: 1px solid lightgray;
    }
    
    .landingPageLogin > form > button {
        text-transform: inherit;
        background-color: rgb(2,42,78);
        color: white;
        transition: all 0.3s ease-in-out;
        width: fit-content;
        margin-left: auto;
    }
    
    .landingPageLogin > form > button:hover {
        background-color: rgb(248,201,24);
    }
}





@media (min-width: 600px) and (max-width: 1024px) {
    .landingPageLogin {
        width: 100%;
        min-height: 80vh;
        display: flex;
        flex-direction: column;
        background: url('../../images/log.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    
    
    .landingPageLogin > form {
        width: 60%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 50px 20px;
        margin: auto;
        background-color: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    }
    
    .landingPageLogin > form > input {
        width: 100%;
        padding: 10px;
        outline: none;
        border: 5px;
        border: 1px solid lightgray;
    }
    
    .landingPageLogin > form > button {
        text-transform: inherit;
        background-color: rgb(2,42,78);
        color: white;
        transition: all 0.3s ease-in-out;
        width: fit-content;
        margin-left: auto;
    }
    
    .landingPageLogin > form > button:hover {
        background-color: rgb(248,201,24);
    }
}