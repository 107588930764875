.MiddleNavbarContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10%;
    padding: 20px 100px;
    align-items: center;
}



.MiddleNavbarContainer > .left {
    display: flex;
    flex-direction: column;
    margin-right: 200px;
}

.leftLogoInitial {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.logo {
    display: flex;
    width: auto;
    height: 100px;
}

.logo > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.logo > .rightMenu {
    display: none;
    color: rgb(12,46,78);
    font-weight: bolder;
}


.fullName {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-left: 4px solid rgb(12,46,78);
    padding: 10px 20px;
}

.fullName > h2 {
    max-width: 200px;
    font-size: 24px;
    color: rgb(12,46,78);
}

.tagline {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tagline > p {
    color: rgb(192,44,40);
    font-size: 14px;
    padding: 5px 10px;
    font-weight: bolder;
    text-transform: uppercase;
    
}



.right {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.right > button {
    background-color: rgb(192,44,40);
    color: white;
    text-transform: inherit;
    transition: all 0.3s ease-in-out;
}

.right > button:hover {
    background-color: rgb(192,44,40);
    color: rgb(251,182,28);
}


.right > .socialIcons {
    display: flex;
    align-items: center;
    gap: 20px;
    color: rgb(12,46,78);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.right > .socialIcons > .icon:hover {
    color: rgb(251,182,28);
}





/* Styles for screens smaller than 576px (XS) */
@media (max-width: 1024px) {
    .MiddleNavbarContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 10%;
        padding: 5px 10px;
        align-items: center;
    }
    
    
    
    .MiddleNavbarContainer > .left {
        display: flex;
        flex-direction: column;
        margin-right: 0px;
    }
    
    .leftLogoInitial {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    
    .logo {
        display: flex;
        width: 100%;
        margin-right: auto;
        align-items: center;
        justify-content: space-between;
    }

    
    .logo > img {
        width: auto;
        height: 80px;
        object-fit: cover;
    }

    .logo > .rightMenu {
        display: block;
        font-size: 40px;
        margin-left: auto;
    }
    
    
    .fullName {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: none;
        padding: 10px 20px;
        display: none;
    }
    
    .fullName > h2 {
        max-width: 200px;
        font-size: 24px;
        color: rgb(12,46,78);
    }
    
    .tagline {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .tagline > p {
        color: rgb(192,44,40);
        font-size: 11px;
        padding: 5px 10px;
        font-weight: bolder;
        text-transform: uppercase;
    }


    
    .right {
        flex-direction: column;
        gap: 10px;
        margin-top: 5px;
        display: none;
    }
    
    .right > button {
        background-color: rgb(192,44,40);
        color: white;
        text-transform: inherit;
        transition: all 0.3s ease-in-out;
    }
    
    .right > button:hover {
        background-color: rgb(192,44,40);
        color: rgb(251,182,28);
    }
    
    
    .right > .socialIcons {
        display: flex;
        align-items: center;
        gap: 20px;
        color: rgb(12,46,78);
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }
    
    .right > .socialIcons > .icon:hover {
        color: rgb(251,182,28);
    }
}