.Apple {
    text-align: center;
    margin: 0 auto;
    max-width: 90%; /* Make the width relative to screen size */
    box-sizing: border-box;
    padding: 50px 0;
    position: relative;
    min-height: 100vh;
  }
  
  header {
    padding: 20px;
  }
  
  .header-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    margin: 0 auto;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .header-content > p {
    width: 100%; /* Full width for small screens */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    margin-bottom: auto;
    margin-top: 0 !important;
  }
  
  .header-images {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    flex-wrap: wrap;
  }
  
  .header-images img {
    width: 100%; 
    height: 40vh;
    max-width: 49%;
    border-radius: 8px;
    object-fit: cover;
  }
  
  .main-content {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    margin: 0 auto;
    flex-wrap: wrap;
    text-align: start;
  }
  
  .staff-spotlight, .contact-us {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%; 
  }
  
  .staff-spotlight > h2, .contact-us > h2 {
    font-size: 2em;
  }
  
  .staff-spotlight ul, .contact-us ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .staff-spotlight ul li, .contact-us ul li {
    text-align: left;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  
  footer {
    margin-top: 20px;
  }
  
  footer p {
    margin: 0;
    padding: 20px 0;
    text-align: start;
  }
  
  .hesen {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  /* Responsive Design */
  @media (min-width: 768px) {
    .Apple {
      max-width: 1200px; /* Adjust for larger screens */
      padding: 50px 20px; 
    }
  
    .header-content > p {
      width: 49%; /* Half width for tablets and above */
    }
  
    .staff-spotlight, .contact-us {
      width: 48%; /* Split content into two columns for larger screens */
    }
  }
  
  @media (max-width: 768px) {
    .header-images img {
      width: 100%; /* Full width for small screens */
      height: auto;
    }
  
    .staff-spotlight, .contact-us {
      width: 100%; /* Stack elements for mobile */
    }
  
    .header-content > p {
      width: 100%; /* Full width for smaller screens */
    }
  }
  