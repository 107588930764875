.landingPageDivision3{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.landingPageDivision3> div {
    display: flex;
    width: 60%;
    gap: 20px;
    margin: 100px auto 50px auto;
}


.landingPageDivision3> div > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.landingPageDivision3> div > div > button {
    background-color: rgb(245,143,30);
    color: white;
    padding: 5px 0;
    margin-right: auto;
    border: 1px solid rgb(245,143,30);
}


.landingPageDivision3> div > div > button:hover {
    background-color: white;
    color: rgb(245,143,30);
}


.landingPageDivision3> div > div > .locTime {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 10%;
    gap: 20px;
}

.landingPageDivision3> div > div > .locTime > div {
    width: 45%;
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(211, 211, 211, 0.8);
    gap: 10px;
    padding: 10px;
}

.landingPageDivision3> div > div > .locTime > div > h4 {
    color: rgb(44, 42, 42);
}

.landingPageDivision3> div > div > .locTime > div > p {
    font-size: small;
    color: rgb(82, 79, 79);
}

.huh2Parent {
    display: flex;
    flex-direction: column;
}

.huh2 {
    display: flex;
    flex-wrap: wrap;
    gap: 10%;
    gap: 20px;
    padding: 10px;
}


.huh2 > img {
    width: 45%;
    height: 40vh;
    object-fit: cover;
    margin: auto;
    border-radius: 10px;
}







.landingPageDivision3> .child {
    flex: 1;
    padding: 20px;
    display: flex;
    gap: 20px;
    padding: 0 20px 200px 20px;
}

.landingPageDivision3> .child > button {
    background-color: rgb(2,42,78);
    color: white;
    transition: all 0.3s ease-in-out;
    text-transform: inherit;
    width: fit-content;
    margin-left: auto;
}


.landingPageDivision3> .child > button:hover {
    background-color: rgb(255,182,44);
}

.landingPageDivision3> .child > div {
    flex: 1;
    display: flex;
    gap: 20px;
    padding: 20px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background-color: rgb(249,250,251);
}



.landingPageDivision3> .child > div:last-of-type {
    border-bottom: none;
}


.landingPageDivision3> .child > div > div:nth-of-type(1) {
    flex: 1;
    display: flex;
    height: 20vh;
}

.landingPageDivision3> .child > div > div:nth-of-type(1) > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.landingPageDivision3> .child > div > div:nth-of-type(2) {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.landingPageDivision3> .child > div > div:nth-of-type(2) > h4 {
    color: rgb(0,58,112);
}

.landingPageDivision3> .child > div > div:nth-of-type(2) > h3:hover {
    text-decoration: underline;
    color: rgb(0,58,112);
    transition: all 0.3s ease-in-out;
}



@media (max-width: 1024px) {
    .landingPageDivision3{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 50px auto;
    }
    
    .landingPageDivision3> div {
        display: flex;
        flex-direction: column;
        width: 90%;
        gap: 20px;
        margin: 100px auto 50px auto;
    }
    
    
    .landingPageDivision3> div > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .landingPageDivision3> div > div .huh2 {
        width: 100%;
        min-height: 20vh;
        margin: 20px auto;
    }
    

    
    .landingPageDivision3> div > div > div > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    
    
    
    
    
    .landingPageDivision3> .child {
        flex: 1;
        padding: 20px;
        display: flex;
        gap: 20px;
        padding: 0 0px 100px 0px;
    }
    
    .landingPageDivision3> .child > button {
        background-color: rgb(2,42,78);
        color: white;
        transition: all 0.3s ease-in-out;
        text-transform: inherit;
        width: fit-content;
        margin-left: auto;
    }
    
    
    .landingPageDivision3> .child > button:hover {
        background-color: rgb(255,182,44);
    }
    
    .landingPageDivision3> .child > div {
        flex: 1;
        display: flex;
        gap: 20px;
        padding: 20px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        background-color: rgb(249,250,251);
    }
    
    
    
    .landingPageDivision3> .child > div:last-of-type {
        border-bottom: none;
    }
    
    
    .landingPageDivision3> .child > div > div:nth-of-type(1) {
        flex: 1;
        display: flex;
        height: 20vh;
    }
    
    .landingPageDivision3> .child > div > div:nth-of-type(1) > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    
    .landingPageDivision3> .child > div > div:nth-of-type(2) {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    
    
    .landingPageDivision3> .child > div > div:nth-of-type(2) > h4 {
        color: rgb(0,58,112);
    }
    
    .landingPageDivision3> .child > div > div:nth-of-type(2) > h3:hover {
        text-decoration: underline;
        color: rgb(0,58,112);
        transition: all 0.3s ease-in-out;
    }
}