/* Base styles */
.banner-sliders-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80vh;
  padding: 0 0px;
  position: relative;
}

.banner-sliders-container > button {
  background-color: rgb(2,42,78);
  color: white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.sliders {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.sliders-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slidem {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
}

.slidem img {
  width: 100%;
  height: 40vh;
  object-fit: cover;
  image-rendering: auto;
}

.slidem > .descriptionHolder {
  width: 100%;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
}




.nav-button {
  background-color: white;
  color: #0074d9;
  border: none;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.nav-button.arrow {
  font-size: 24px;
}



/* Small screens */
@media (max-width: 575.98px) {
  /* Base styles */
  .banner-sliders-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 50vh;
    padding: 0;
    position: relative;
  }

  .sliders {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .sliders-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in-out;
  }

  .slidem {
    flex: 0 0 100%;
    display: flex;
    flex-direction: column;
  }
  
  .slidem img {
    width: 100%;
    height: 40vh;
    object-fit: cover;
    image-rendering: auto;
  }
  
  .slidem > .descriptionHolder {
    width: 100%;
    min-height: 20vh;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  
  
  



  .nav-button.arrow {
    font-size: 24px;
  }

  .dots {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 20px;
  }

  .dot {
    width: 12px;
    height: 12px;
    background-color: #bbb;
    border-radius: 50%;
    margin: 0 6px;
    cursor: pointer;
  }

  .dot.active {
    background-color: rgb(249,179,71);
  }
}



/* Tablets: screens with a width between 600px and 1024px */
@media (min-width: 600px) and (max-width: 1024px) {
  /* Base styles */
  .banner-sliders-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80vh;
    padding: 0;
    position: relative;
  }

  .sliders {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .sliders-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in-out;
  }

  .slidem {
    flex: 0 0 100%;
    display: flex;
    flex-direction: column;
  }
  
  .slidem img {
    width: 100%;
    height: 40vh;
    object-fit: cover;
    image-rendering: auto;
  }
  
  .slidem > .descriptionHolder {
    width: 100%;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  

  



  .nav-button.arrow {
    font-size: 24px;
  }

  .dots {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 20px;
  }

  .dot {
    width: 12px;
    height: 12px;
    background-color: #bbb;
    border-radius: 50%;
    margin: 0 6px;
    cursor: pointer;
  }

  .dot.active {
    background-color: rgb(249,179,71);
  }
}
