.landingPageResearch {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.landingPageResearch > div:nth-of-type(1) {
    width: 100%;
    height: 60vh;
    display: flex;
    position: relative;
}

.landingPageResearch > div:nth-of-type(1) > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.landingPageResearch > div:nth-of-type(1) > h1 {
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translateX(-50%);
    background-color: rgb(2,42,78);
    color: white;
    padding: 10px 20px;
}



.landingPageResearch > .second  {
    width: 60%;
    display: flex;
    margin: 50px auto 0 auto;
    background-color: white;
    padding: 40px;
    border: 4px solid rgb(2,42,78);
    font-weight: bolder;
}

.landingPageResearch > .second  > h2 {
    font-weight: 400;
}

.landingPageResearch > div:nth-of-type(2) > div:nth-of-type(1)  {
    flex: 2;
    text-decoration: line-through;
    position: relative;
}

.landingPageResearch > div:nth-of-type(2) > div:nth-of-type(1)::before {
    content: '';
    position: absolute;
    top: 50%; /* Position it vertically at 50% of the parent div */
    left: 0; /* Position it at the left edge of the parent div */
    width: 100%; /* Make it extend the full width of the parent div */
    border-top: 1px solid lightgray; /* Color and style of the line */
}


.landingPageResearch > div:nth-of-type(2) > div:nth-of-type(2)  {
    flex: 4;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landingPageResearch > div:nth-of-type(2) > div:nth-of-type(3)  {
    flex: 2;
    text-decoration: line-through;
    position: relative;
}

.landingPageResearch > div:nth-of-type(2) > div:nth-of-type(3) > h1 {
    font-size: 30px;
}

.landingPageResearch > div:nth-of-type(2) > div:nth-of-type(3)::before {
    content: '';
    position: absolute;
    top: 50%; /* Position it vertically at 50% of the parent div */
    left: 0; /* Position it at the left edge of the parent div */
    width: 100%; /* Make it extend the full width of the parent div */
    border-top: 1px solid lightgray; /* Color and style of the line */
}


.landingPageResearch > .third {
    width: 80%;
    display: flex;
    background-color: white;
    padding: 50px 0;
    margin: 0px auto 50px auto;
    gap: 20px;
}

.landingPageResearch > .third > .left {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
}



@media (max-width: 1024px) {
    .landingPageResearch {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 10px;
    }
    
    .landingPageResearch > div:nth-of-type(1) {
        width: 100%;
        height: 40vh;
        display: flex;
        position: relative;
    }
    
    .landingPageResearch > div:nth-of-type(1) > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .landingPageResearch > div:nth-of-type(1) > h1 {
        position: absolute;
        left: 50%;
        bottom: 20%;
        background-color: rgb(2,42,78);
        color: white;
        padding: 10px 20px;
        text-align: center;
    }
    
    
    
    .landingPageResearch > div:nth-of-type(2)  {
        width: 100%;
        display: flex;
        background-color: white;
        padding: 10px;
    }
    
    .landingPageResearch > div:nth-of-type(2) > div:nth-of-type(1)  {
        flex: 1;
        text-decoration: line-through;
        position: relative;
    }
    
    .landingPageResearch > div:nth-of-type(2) > div:nth-of-type(1)::before {
        content: '';
        position: absolute;
        top: 50%; /* Position it vertically at 50% of the parent div */
        left: 0; /* Position it at the left edge of the parent div */
        width: 100%; /* Make it extend the full width of the parent div */
        border-top: 1px solid lightgray; /* Color and style of the line */
    }
    
    
    .landingPageResearch > div:nth-of-type(2) > div:nth-of-type(2)  {
        flex: 4;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .landingPageResearch > div:nth-of-type(2) > div:nth-of-type(3)  {
        flex: 1;
        text-decoration: line-through;
        position: relative;
    }
    
    .landingPageResearch > div:nth-of-type(2) > div:nth-of-type(3) > h1 {
        font-size: 30px;
    }
    
    .landingPageResearch > div:nth-of-type(2) > div:nth-of-type(3)::before {
        content: '';
        position: absolute;
        top: 50%; /* Position it vertically at 50% of the parent div */
        left: 0; /* Position it at the left edge of the parent div */
        width: 100%; /* Make it extend the full width of the parent div */
        border-top: 1px solid lightgray; /* Color and style of the line */
    }
    
    
    .landingPageResearch > div:nth-of-type(3)  {
        width: 90%;
        display: flex;
        background-color: white;
        padding: 10px 0;
        margin: 0px auto 10px auto;
    }
}