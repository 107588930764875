.teamTypeContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 100px;
}

.teamTypeContainerFirst {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 4%;
    margin: 50px 0;
}

.teamTypeContainerFirst > div {
    width: 22%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    padding-bottom: 10px;
}

.teamTypeContainerFirst > div > h2 {
    font-size: smaller;
    padding: 0 10px;
}

.teamTypeContainerFirst > div > p {
    font-size: medium;
    padding: 0 10px;
}

.teamTypeContainerFirst > div > img {
    width: 100%;
    height: 40vh;
    object-fit: cover;
    object-position: center;
    border-bottom: 4px solid rgb(248,203,23);
}




@media (max-width: 575.98px) {
    .teamTypeContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 100px 20px;
    }
    
    .teamTypeContainerFirst {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 4%;
        margin: 50px 0;
    }

    .teamTypeContainerFirst > div > h2 {
        font-size: smaller;
    }
    
    .teamTypeContainerFirst > div > p {
        font-size: smaller;
    }
    
    .teamTypeContainerFirst > div {
        width: 98%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
    }
    
    .teamTypeContainerFirst > div > img {
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: center;
    }
}


@media (min-width: 600px) and (max-width: 1024px) {
    .teamTypeContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 100px 20px;
    }
    
    .teamTypeContainerFirst {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 4%;
        margin: 50px 0;
    }


    .teamTypeContainerFirst > div > h2 {
        font-size: smaller;
    }
    
    .teamTypeContainerFirst > div > p {
        font-size: medium;
    }
    
    .teamTypeContainerFirst > div {
        width: 48%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;
    }
    
    .teamTypeContainerFirst > div > img {
        width: 100%;
        height: 40vh;
        object-fit: cover;
        object-position: center;
        object-position: center;
    }
}