.landingPageDonateMain {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: url('../../images/don4.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    mix-blend-mode: darken;
}

.landingPageDonate {
    width: 60%;
    display: flex;
    flex-direction: column;
    margin: 100px auto;
    align-items: center;
    background-color: rgb(255, 255, 255);
    padding: 20px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
}


.payPalButton {
    background-color: rgb(245,143,30);
    color: white;
    padding: 5px 0;
    margin-right: auto;
    border: 1px solid rgb(245,143,30);
    padding: 10px;
    transition: all 0.3s ease-in-out;
    border-radius: 5px;
    cursor: pointer;
}


.payPalButton:hover {
    background-color: white;
    color: rgb(245,143,30);
}


.landingPageDonate > p {
    margin: 40px auto;
    text-align: center;
    width: 80%;
}

.involved {
    width: 100%;
    margin: 40px auto;
    border: 1px solid rgba(211, 211, 211, 0.5);
    display: flex;
    gap: 4%;
    border-radius: 10px;
    padding: 20px;
}


.involved > div:nth-of-type(1) {
    flex: 3;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.involved > div:nth-of-type(2) {
    flex: 2;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(211, 211, 211, 0.5);
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 20px;
    text-align: center;
}


.involved > div:nth-of-type(2) > button {
    background-color: rgb(245,143,30);
    color: white;
    padding: 5px 0;
    border: 1px solid rgb(245,143,30);
}


.involved > div:nth-of-type(2) > button:hover {
    background-color: white;
    color: rgb(245,143,30);
}

.involved > div:nth-of-type(2) > p:nth-of-type(1) {
    font-size: medium;
}


.involved > div:nth-of-type(2) > p:nth-of-type(2) {
    font-size: small;
}


.involvedType {
    display: flex;
    gap: 10%;
}

.involvedType > div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-top: 1px solid rgba(211, 211, 211, 0.8);
    padding: 20px 10px;
}

.involvedType > div > p {
    display: flex;
    align-items: center;
    gap: 5px;
}


@media (max-width: 575.98px) {
    .landingPageDonateMain {
        width: 100%;
        display: flex;
        flex-direction: column;
        background: url('../../images/don4.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        mix-blend-mode: darken;
    }
    
    .landingPageDonate {
        width: 90%;
        display: flex;
        flex-direction: column;
        margin: 150px auto;
        align-items: center;
        background-color: rgb(255, 255, 255);
        padding: 20px;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
        border-radius: 10px;
    }
    
    .landingPageDonate > p {
        margin: 40px auto;
        text-align: center;
        width: 80%;
    }
    
    .involved {
        width: 100%;
        margin: 40px auto;
        border: 1px solid rgba(211, 211, 211, 0.5);
        display: flex;
        flex-direction: column;
        gap: 4%;
        border-radius: 10px;
        padding: 20px;
    }
    
    
    .involved > div:nth-of-type(1) {
        flex: 3;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    
    .involved > div:nth-of-type(2) {
        flex: 2;
        display: flex;
        flex-direction: column;
        border: 1px solid rgba(211, 211, 211, 0.5);
        border-radius: 10px;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 20px;
        text-align: center;
    }
    
    
    .involved > div:nth-of-type(2) > button {
        background-color: rgb(245,143,30);
        color: white;
        padding: 5px 0;
        border: 1px solid rgb(245,143,30);
    }
    
    
    .involved > div:nth-of-type(2) > button:hover {
        background-color: white;
        color: rgb(245,143,30);
    }
    
    .involved > div:nth-of-type(2) > p:nth-of-type(1) {
        font-size: medium;
    }
    
    
    .involved > div:nth-of-type(2) > p:nth-of-type(2) {
        font-size: small;
    }
    
    
    .involvedType {
        display: flex;
        gap: 10%;
    }
    
    .involvedType > div {
        display: flex;
        flex-direction: column;
        gap: 20px;
        border-top: 1px solid rgba(211, 211, 211, 0.8);
        padding: 20px 10px;
    }
    
    .involvedType > div > p {
        display: flex;
        align-items: center;
        gap: 5px;
    }
}