table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

th, td {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left;
}

th {
    background-color: #f1f1f1;
}



.landingPageWhoWeAre {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.landingPageWhoWeAreBanner {
    width: 100%;
    height: 50vh;
}

.landingPageWhoWeAreBanner > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.landingPageWhoWeAre > h2 {
    margin: 50px auto;
    font-weight: bold;
    
}

.landingPageWhoWeAre > p {
    margin: 50px auto;
    width: 60%;
}

.landingPageWhoWeAre > p:nth-of-type(2) {
    margin: 0px auto 50px auto;
}

.landingPageWhoWeAre > .first {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* padding: 100px; */
    background-color: rgb(254,251,254);
    gap: 5px;
}

.landingPageWhoWeAre > .first > .firstBox {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 10px auto;
    /* padding: 40px; */
}

.landingPageWhoWeAre > .first > .firstBox > h2 {
    text-align: center;
    padding: 20px;
}


.landingPageWhoWeAre > .first > .firstBox > p {

   border:  4px solid rgb(12,46,78) ;
    padding: 40px;
}


.landingPageWhoWeAre > .first > .firstBoxMenu {
    width: 80%;
    display: flex;
    gap: 20px;
    margin: 10px auto;
    align-self: center;
    justify-content: center;
    /* padding: 10px; */
    
} 

.title{ 
    margin: 5px 2px;
}
.designation{
    color: gray;
    margin-left: 10px;
    font-style: italic;
    font-size: medium;
}
.workstation{
    color: rgb(110, 108, 108);
    font-size: medium;
    margin-left: 10px;
}

.landingPageWhoWeAre > .first > .firstBoxMenu > p {
    border: 1px solid rgb(248,189,84, .8);
    padding: 10px;
    border-radius: 20px;
}

.landingPageWhoWeAre > .first > .firstBoxContent {
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    gap: 4%;
    margin: 10px auto;
    padding: 10px;
}

.landingPageWhoWeAre > .first > .firstBoxContent > div {
    width: 48%;
    display: flex;
    padding: 20px;
    background-color: #fcfbfb;
    margin-bottom: 20px;
    gap: 5px;
}

.landingPageWhoWeAre > .first > .firstBoxContent > div > div:nth-of-type(1) {
    flex: 1;
    display: flex;
    align-items: flex-start;
}

.landingPageWhoWeAre > .first > .firstBoxContent > div > div:nth-of-type(2) {
    flex: 11;
}


.activeCategory {
    color: rgb(248,189,84); /* You can replace 'blue' with your desired color */
    /* Add any other styles you want for the active category */
  }


.landingPageWhoWeAre > .first > div {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 2%;
    gap: 20px;
    padding-bottom: 20px;
}

.landingPageWhoWeAre > .first > div > p:hover {
    transition: all 0.3s ease-in-out;
    /* color: rgb(248,189,84); */
    cursor: pointer;
}





.landingPageWhoWeAre > .second {
    width: 100%;
    padding: 50px 100px;
    display: flex;
    flex-direction: column;
    background-color: rgb(0,58,112);
}

.landingPageWhoWeAre > .second > div:nth-of-type(1) {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.landingPageWhoWeAre > .second > div:nth-of-type(1) > h3 {
    margin: auto;
    color: white;
    font-size: 40px;
}

.landingPageWhoWeAre > .second > div:nth-of-type(2) {
    display: flex;
    flex: 1;
    gap: 5%;
    margin: 50px 0;
}

.landingPageWhoWeAre > .second > div:nth-of-type(2) > div {
    flex: 1;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    padding-bottom: 20px;
}

.landingPageWhoWeAre > .second > div:nth-of-type(2) > div > h3,
.landingPageWhoWeAre > .second > div:nth-of-type(2) > div > p {
    margin: 0 20px;
}

.landingPageWhoWeAre > .second > div:nth-of-type(2) > div > p:nth-of-type(2) {
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    margin-top: auto;
}

.landingPageWhoWeAre > .second > div:nth-of-type(2) > div > p:nth-of-type(2):hover {
    color: rgb(0,58,112);
}

.landingPageWhoWeAre > .second > div:nth-of-type(2) > div > img {
    width: 100%;
    height: 40vh;
    object-fit: cover;
    border-bottom: 4px solid rgb(248,189,84);
}

.landingPageWhoWeAre > .second > div:nth-of-type(2) > div > video {
    width: 100%;
    height: 100%;
    object-fit: fill;
}


.landingPageWhoWeAre > .third {
    width: 40%;
    display: flex;
    margin: 0px auto;
}

.landingPageWhoWeAre > .third > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.members-list{
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    gap: 20px;
    justify-content: space-between;
    width: 100%;
}





@media (max-width: 575.98px) {
    .landingPageWhoWeAre {
        display: flex;
        width: 100%;
        flex-direction: column;
    }
    
    .landingPageWhoWeAre > h2 {
        margin: 10px auto;
        font-weight: bold;
    }
    
    .landingPageWhoWeAre > p {
        margin: 10px auto;
        width: 90%;
    }
    
    .landingPageWhoWeAre > p:nth-of-type(2) {
        margin: 0px auto 10px auto;
    }
    
    .landingPageWhoWeAre > .first {
        width: 100%;
        display: flex;
        flex-direction: column;
        /* justify-content: center;
        align-items: center; */
        /* padding: 100px 10px; */
        background-color: rgb(254,251,254);
        gap: 10px;
    }

    .landingPageWhoWeAre > .first > .firstBox {
        width: 98%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        /* margin: 40px auto; */
        /*border:  4px solid rgb(12,46,78) ; */
        padding: 10px;
    }


    .landingPageWhoWeAre > .first > .firstBoxContent {
        width: 98%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        /* margin: 40px auto; */
        /*border:  4px solid rgb(12,46,78) ; */
        padding: 10px;
    }

    
    .landingPageWhoWeAre > .first > .firstBoxContent > div {
        width: 100%;
        display: flex;
        padding: 10px;
    }
    


    .landingPageWhoWeAre > .first > .firstBoxContent > div > div:nth-of-type(1) {
        flex: 1;
        display: flex;
        align-items: flex-start;
    }
    
    .landingPageWhoWeAre > .first > .firstBoxContent > div > div:nth-of-type(2) {
        flex: 11;
    }
    
    .landingPageWhoWeAre > .first > .firstBoxMenu {
        width: 98%;
        display: flex;
        gap: 10px;
        margin: 10px auto;
        padding: 10px;

    } 
    
    .landingPageWhoWeAre > .first > .firstBoxMenu > p {
        border: 1px solid rgb(248,189,84, .8);
        padding: 10px;
        border-radius: 20px;
    }
    
    .landingPageWhoWeAre > .first > .firstBoxContent {
        width: 98%;
        display: flex;
        gap: 10px;
        margin: 10px auto;
        /* padding: 10px; */
    }

    
    .landingPageWhoWeAre > .first > div {
        display: flex;
        flex-wrap: wrap;
        /* justify-content: center;
        align-items: center; */
        width: 100%;
        gap: 10px;
        padding-bottom: 20px;
        padding-left: 20px;
    }
    
    .landingPageWhoWeAre > .first > div > p:hover {
        transition: all 0.3s ease-in-out;
        /* color: rgb(248,189,84); */
        cursor: pointer;
    }
    
    
    
    .landingPageWhoWeAre > .second {
        width: 100%;
        padding: 0px 0px;
        display: flex;
        flex-direction: column;
        background-color: rgb(0,58,112);
    }
    
    .landingPageWhoWeAre > .second > div:nth-of-type(1) {
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    
    .landingPageWhoWeAre > .second > div:nth-of-type(1) > h3 {
        margin: 10px auto 0 auto;
    }
    
    .landingPageWhoWeAre > .second > div:nth-of-type(2) {
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    
    .landingPageWhoWeAre > .second > div:nth-of-type(2) > div {
        width: 90%;
        height: 40vh;
        margin: 10px auto;
    }
    
    .landingPageWhoWeAre > .second > div:nth-of-type(2) > div > video {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
    
    
    .landingPageWhoWeAre > .third {
        width: 50%;
        display: flex;
        margin: 0px auto;
    }
    
    .landingPageWhoWeAre > .third > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

h6{
    font-weight: bold;
    font-size: 25px;
}

/* Tablets: screens with a width between 600px and 1024px */
@media (min-width: 600px) and (max-width: 1024px) {
    .landingPageWhoWeAre {
        display: flex;
        width: 100%;
        flex-direction: column;
        margin: 150px 0 50px 0;
    }
    
    .landingPageWhoWeAre > h2 {
        margin: 50px auto;
        font-weight: bold;
    }
    
    .landingPageWhoWeAre > p {
        margin: 50px auto;
        width: 90%;
    }
    
    .landingPageWhoWeAre > p:nth-of-type(2) {
        margin: 0px auto 50px auto;
    }
    
    .landingPageWhoWeAre > .first {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 100px 10px;
        background-color: rgb(254,251,254);
        gap: 40px;
    }


    .landingPageWhoWeAre > .first > .firstBox {
        width: 80%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 20px auto;
        padding: 20px;
    }


    .landingPageWhoWeAre > .first > .firstBoxContent {
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        gap: 4%;
        margin: 10px auto;
        padding: 10px;
        justify-content: flex-start;
    }
    
    .landingPageWhoWeAre > .first > .firstBoxContent > div {
        width: 48%;
        display: flex;
        padding: 20px;
    }

    .landingPageWhoWeAre > .first > .firstBoxContent > div > div:nth-of-type(1) {
        flex: 1;
        display: flex;
        align-items: flex-start;
    }
    
    .landingPageWhoWeAre > .first > .firstBoxContent > div > div:nth-of-type(2) {
        flex: 11;
    }
    
    
    .landingPageWhoWeAre > .first > .firstBoxMenu {
        width: 80%;
        display: flex;
        gap: 20px;
        margin: 10px auto;
        padding: 40px;
    } 
    
    .landingPageWhoWeAre > .first > .firstBoxMenu > p {
        border: 1px solid rgb(248,189,84, .8);
        padding: 10px;
        border-radius: 20px;
    }
    

    
    .landingPageWhoWeAre > .first > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 4%;
        gap: 20px;
        padding-bottom: 20px;
    }
    
    .landingPageWhoWeAre > .first > div > p:hover {
        transition: all 0.3s ease-in-out;
        /* color: rgb(248,189,84); */
        cursor: pointer;
    }
    
    
    
    .landingPageWhoWeAre > .second {
        width: 100%;
        padding: 50px 20px;
        display: flex;
        flex-direction: column;
        background-color: rgb(0,58,112);
    }
    
    .landingPageWhoWeAre > .second > div:nth-of-type(1) {
        display: flex;
        flex-direction: column;
        flex: 1;
    }
    
    .landingPageWhoWeAre > .second > div:nth-of-type(1) > h3 {
        margin: auto;
    }
    
    .landingPageWhoWeAre > .second > div:nth-of-type(2) {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
    }
    
    .landingPageWhoWeAre > .second > div:nth-of-type(2) > div {
        width: 48%;
        min-height: 40vh;
        margin: 40px auto;
    }


    
    .landingPageWhoWeAre > .second > div:nth-of-type(2) > div > img {
        width: 100%;
        height: auto;
        object-fit: fill;
    }
    
    
    .landingPageWhoWeAre > .third {
        width: 50%;
        display: flex;
        margin: 0px auto;
    }
    
    .landingPageWhoWeAre > .third > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}