.landingPageTroubleElection {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.landingPageTroubleElection > div {
    display: flex;
    flex-direction: column-reverse;
    width: 60%;
    gap: 20px;
    margin: 100px auto 50px auto;
}


.landingPageTroubleElection > div > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    text-align: center;
}


.huh {
    width: 60%;
    height: 40vh;
    margin: auto;
}



.landingPageTroubleElection > div > div > div > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}



.readButton {
    background-color: rgb(255,182,44);
    color: white;
    border: 1px solid rgb(255,182,44);
    transition: all 0.3s ease-in-out;
}

.readButton:hover {
    background-color: white;
    color: rgb(255,182,44);
}


.landingPageTroubleElection > .child {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding-bottom: 200px;
    flex-direction: row; /* Set the flex-direction to row */
}

.landingPageTroubleElection > .child > div {
    max-width: calc(33.333% - 20px); /* Adjusted max-width for three cards per row */
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background-color: rgb(249, 250, 251);
    border-bottom: 1px solid rgba(249, 250, 251, 0.6); /* Add a border between div elements */
}


.landingPageTroubleElection > .child > button {
    background-color: rgb(2,42,78);
    color: white;
    transition: all 0.3s ease-in-out;
    text-transform: inherit;
    width: fit-content;
    margin-left: auto;
}







@media (max-width: 575.98px) {
    .landingPageTroubleElection {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 100px;
    }

    .landingPageTroubleElection > div {
        width: 90%;
        margin: 20px auto;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .landingPageTroubleElection > div:nth-of-type(1) {
        flex-direction: column-reverse;
    }

    .landingPageTroubleElection > div > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .huh {
        width: 80%;
        height: 40vh;
        margin: auto;
    }

    .landingPageTroubleElection > div > div > div > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .landingPageTroubleElection > .child {
        flex: 1;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 0 20px 200px 20px;
    }

    .landingPageTroubleElection > .child > button {
        background-color: rgb(2,42,78);
        color: white;
        transition: all 0.3s ease-in-out;
        text-transform: inherit;
        width: fit-content;
        margin-left: auto;
    }

    .landingPageTroubleElection > .child > button:hover {
        background-color: rgb(255, 182, 44);
    }

 .landingPageTroubleElection > .child > div {
        flex: 1;
        max-width: 100%; /* Take full width */
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        background-color: rgb(249, 250, 251);
        border-bottom: 1px solid #ccc; /* Add a border between div elements */
        margin-bottom: 20px; /* Add margin to separate rows */
    }

    .landingPageTroubleElection > .child > button {
        background-color: rgb(2,42,78);
        color: white;
        transition: all 0.3s ease-in-out;
        text-transform: inherit;
        width: fit-content;
        margin-left: auto;
        margin-bottom: 20px; /* Add margin to separate rows */
    }

    .landingPageTroubleElection > .child > button:hover {
        background-color: rgb(255, 182, 44);
    }

    .landingPageTroubleElection > .child > div:last-of-type {
        border-bottom: none;
        margin-bottom: 0; /* Remove margin for the last item */
    }
}



@media (min-width: 600px) and (max-width: 1024px) {
    .landingPageTroubleElection {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 200px auto 50px auto;
    }
    
    .landingPageTroubleElection > div {
        display: flex;
        width: 95%;
        gap: 20px;
        margin: auto;
    }
    
    
    .landingPageTroubleElection > div > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 20px;
        position: relative;
    }
    
    
    .huh {
        width: 50%;
        height: 40vh;
        margin: auto;
    }
    
    
    
    .landingPageTroubleElection > div > div > div > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    
    
    .readButton {
        background-color: rgb(255,182,44);
        color: white;
        border: 1px solid rgb(255,182,44);
        transition: all 0.3s ease-in-out;
    }
    
    .readButton:hover {
        background-color: white;
        color: rgb(255,182,44);
    }
    
    
    .landingPageTroubleElection > .child {
        flex: 1;
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        padding-bottom: 200px;
    }
    
    .landingPageTroubleElection > .child > div {
        flex: 1 0 calc(33.333% - 20px); /* Adjusted flex-basis for three columns per row */
        max-width: calc(33.333% - 20px); /* Adjusted max-width for three columns per row */
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        background-color: rgb(249, 250, 251);
        border-bottom: 1px solid rgb(249,250,251, .6) /* Add a border between div elements */
    }
    
    .landingPageTroubleElection > .child > button {
        background-color: rgb(2,42,78);
        color: white;
        transition: all 0.3s ease-in-out;
        text-transform: inherit;
        width: fit-content;
        margin-left: auto;
    }
    
    .landingPageTroubleElection > .child > button:hover {
        background-color: rgb(255, 182, 44);
    }
    
    
    .landingPageTroubleElection > .child > div > div:nth-of-type(1) {
        flex: 1;
        display: flex;
        height: 20vh;
    }
    
    .landingPageTroubleElection > .child > div > div:nth-of-type(1) > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .landingPageTroubleElection > .child > div > div:nth-of-type(2) {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    
    .landingPageTroubleElection > .child > div > div:nth-of-type(2) > h4 {
        color: rgb(0, 58, 112);
    }
    
    .landingPageTroubleElection > .child > div > div:nth-of-type(2) > h3:hover {
        text-decoration: underline;
        color: rgb(0, 58, 112);
        transition: all 0.3s ease-in-out;
    }
}