.landingPageFooter {
    width: 100%;
    background-color: rgb(2,42,78);
    padding: 100px 0 0px 0;
    display: flex;
    flex-direction: column;
    color: white;
}

.landingPageFooter > h2 {
    margin: 0 auto;
}

.landingPageFooter > span {
    margin: 40px auto;
    min-width: 100px;
    height: 8px;
    background-color: rgb(255,182,44);
    display: flex;
    align-items: center;
    justify-content: center;
}

.footerLinkTag {
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(2,42,78);
    border-radius: 100%;
}

.landingPageFooter > .firstNth {
    width: 25%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.5);
    padding: 0 !important;
    background-color: transparent !important;
}


.landingPageFooter > .firstNth > input {
    display: flex;
    flex-grow: 1;
    background-color: transparent;
    border: none;
    padding: 10px;
    transition: all 0.3s ease-in-out;
    color: white;
}

.landingPageFooter > .firstNth > .rightIcon {
 font-size: 25px;
 cursor: pointer;
 margin: 10px;
}


.landingPageFooter > .secondNth {
    width: 90%;
    display: flex;
    margin: 40px auto;
}

.landingPageFooter > .secondNth > div:nth-of-type(1) {
    flex: 2;
    height: 4vh;
    display: flex;
    background-image: repeating-linear-gradient(45deg, transparent, transparent 10px, lightgray 10px, lightgray 20px); /* Adjust colors and size as needed */
    background-size: 20px 20px; /* Adjust the size of the stripes */
    opacity: .5;
}


.landingPageFooter > .secondNth > div:nth-of-type(2) {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.landingPageFooter > .secondNth > div:nth-of-type(2) > span {
    color: rgb(2,42,78);
    background-color: white;
    padding: 5px;
    border-radius: 100%;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.landingPageFooter > .secondNth > div:nth-of-type(3) {
    flex: 2;
    height: 4vh;
    display: flex;
    background-image: repeating-linear-gradient(45deg, transparent, transparent 10px, lightgray 10px, lightgray 20px); /* Adjust colors and size as needed */
    background-size: 20px 20px; /* Adjust the size of the stripes */
    opacity: .5;
}


.landingPageFooter > .thirdNth {
    width: 80%;
    margin: 0 auto;
    display: flex;
}


.landingPageFooter > .thirdNth > div:nth-of-type(1) {
    display: flex;
    flex: 1;
    gap: 20px;
    align-items: center;
}

.landingPageFooter > .thirdNth > div:nth-of-type(1) > div:nth-of-type(1) {
    display: flex;
    flex: 1;
}

.landingPageFooter > .thirdNth > div:nth-of-type(1) > div:nth-of-type(1) > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.landingPageFooter > .thirdNth > div:nth-of-type(1) > div:nth-of-type(2) {
    display: flex;
    flex: 4;
    flex-direction: column;
}

.landingPageFooter > .thirdNth > div:nth-of-type(1) > div:nth-of-type(2) > p {
    width: 60%;
}

.landingPageFooter > .thirdNth > div:nth-of-type(1) > div:nth-of-type(2) > button {
    margin-top: 20px;
    width: fit-content;
    background-color: rgb(0,100,159);
    color: white;
    transition: all 0.3s ease-in-out;
    text-transform: inherit;
    padding: 5px 20px;
}

.landingPageFooter > .thirdNth > div:nth-of-type(1) > div:nth-of-type(2) > button:hover {
    background-color: rgb(255,182,44);
}

.landingPageFooter > .thirdNth > div:nth-of-type(2) {
    display: flex;
    flex: 1;
    gap: 10px;
}


.landingPageFooter > .thirdNth > div:nth-of-type(2) > div:nth-of-type(1) {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
}

.landingPageFooter > .thirdNth > div:nth-of-type(2) > div:nth-of-type(1) > p:hover {
    color: rgb(255,182,44);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.landingPageFooter > .thirdNth > div:nth-of-type(2) > div:nth-of-type(2) {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
}

.landingPageFooter > .thirdNth > div:nth-of-type(2) > div:nth-of-type(2) > p:hover {
    color: rgb(255,182,44);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.landingPageFooter > .thirdNth > div:nth-of-type(2) > div:nth-of-type(3) {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
}


.landingPageFooter > .thirdNth > div:nth-of-type(2) > div:nth-of-type(3) > p:hover {
    color: rgb(255,182,44);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}


.landingPageFooter > .fourthNth {
    width: 100%;
    min-height: 6vh;
    background-color: white;
    margin-top: 40px;
    display: flex;
    align-items: center;
    color: black;
    justify-content: space-between;
    padding: 0 50px;
}

.landingPageFooter > .fourthNth > div {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: small;
}

.landingPageFooter > .fourthNth > div > p:hover {
    transition: all 0.3s ease-in-out;
    color: rgb(2,42,78);
    cursor: pointer;
}



@media (max-width: 575.98px) {
    .landingPageFooter {
        width: 100%;
        background-color: rgb(2,42,78);
        padding: 50px 0px 0 0px; /* Adjusted padding */
        display: flex;
        flex-direction: column;
        color: white;
    }
    
    .landingPageFooter > h2 {
        margin: 0 auto;
        text-align: center;
    }
    
    .landingPageFooter > span {
        margin: 10px auto; /* Adjusted margin */
        min-width: 50px; /* Adjusted min-width */
        height: 4px; /* Adjusted height */
        background-color: rgb(255,182,44);
    }
    
    .landingPageFooter > .firstNth {
        width: 90%; /* Adjusted width */
        margin: 0 auto;
        display: flex;
        align-items: center;
    }
    
    .landingPageFooter > .firstNth > input {
        display: flex;
        flex-grow: 1;
        background-color: transparent;
        border: none;
        padding: 5px; /* Adjusted padding */
        transition: all 0.3s ease-in-out;
        color: white;
    }
    
    .landingPageFooter > .firstNth > .rightIcon {
        font-size: 40px; /* Adjusted font size */
        cursor: pointer;
    }
    
    .landingPageFooter > .secondNth {
        width: 100%; /* Adjusted width */
        display: flex;
        margin: 40px auto; /* Adjusted margin */
        align-items: center;
    }
    
    .landingPageFooter > .secondNth > div:nth-of-type(1) {
        flex: 1;
        height: 3vh; /* Adjusted height */
        display: flex;
        background-image: repeating-linear-gradient(45deg, transparent, transparent 5px, lightgray 5px, lightgray 10px);
        background-size: 10px 10px;
        opacity: .5;
    }
    
    .landingPageFooter > .secondNth > div:nth-of-type(2) {
        flex: 6;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px; /* Adjusted gap */
    }
    
    .landingPageFooter > .secondNth > div:nth-of-type(2) > span {
        color: rgb(2,42,78);
        background-color: white;
        padding: 3px;
        border-radius: 50%; /* Adjusted border-radius */
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }
    
    .landingPageFooter > .secondNth > div:nth-of-type(3) {
        flex: 1;
        height: 3vh; /* Adjusted height */
        display: flex;
        background-image: repeating-linear-gradient(45deg, transparent, transparent 5px, lightgray 5px, lightgray 10px);
        background-size: 10px 10px;
        opacity: .5;
    }
    
    .landingPageFooter > .thirdNth {
        width: 100%; /* Adjusted width */
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        padding: 10px;
    }
    
    .landingPageFooter > .thirdNth > div:nth-of-type(1) {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 10px; /* Adjusted gap */
        align-items: center;
        margin: 10px auto;
        padding: 10px 0;
        border-bottom: 1px solid rgba(169, 169, 169, 0.5); 
        border-top: 1px solid rgba(169, 169, 169, 0.5); 
    }
    
    .landingPageFooter > .thirdNth > div:nth-of-type(1) > div:nth-of-type(1) {
        display: flex;
        flex: 1;
    }
    
    .landingPageFooter > .thirdNth > div:nth-of-type(1) > div:nth-of-type(1) > img {
        width: 40%;
        height: auto;
        object-fit: cover;
        margin: auto;
    }
    
    .landingPageFooter > .thirdNth > div:nth-of-type(1) > div:nth-of-type(2) {
        display: flex;
        flex: 3;
        flex-direction: column;
        align-items: flex-end;
        border-left: none; 
    }
    
    .landingPageFooter > .thirdNth > div:nth-of-type(1) > div:nth-of-type(2) > p {
        width: 100%; /* Adjusted width */
        font-size: bolder;
    }
    
    .landingPageFooter > .thirdNth > div:nth-of-type(1) > div:nth-of-type(2) > button {
        margin-top: 10px; /* Adjusted margin */
        width: fit-content;
        background-color: rgb(0,100,159);
        color: white;
        transition: all 0.3s ease-in-out;
        text-transform: inherit;
        padding: 3px 10px; /* Adjusted padding */
    }
    
    .landingPageFooter > .thirdNth > div:nth-of-type(1) > div:nth-of-type(2) > button:hover {
        background-color: rgb(255,182,44);
    }
    
    .landingPageFooter > .thirdNth > div:nth-of-type(2) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 20px;
        flex: 1;
        margin: 20px auto;
        text-align: center;
    }
    
    .landingPageFooter > .thirdNth > div:nth-of-type(2) > div:nth-of-type(1) {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        gap: 10px; /* Adjusted gap */
    }
    
    .landingPageFooter > .thirdNth > div:nth-of-type(2) > div:nth-of-type(1) > p:hover {
        color: rgb(255,182,44);
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }
    
    .landingPageFooter > .thirdNth > div:nth-of-type(2) > div:nth-of-type(2) {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        gap: 10px; /* Adjusted gap */
        align-items: center;
    }


    
    .landingPageFooter > .thirdNth > div:nth-of-type(2) > div:nth-of-type(2) > p:hover {
        color: rgb(255,182,44);
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }
    
    .landingPageFooter > .thirdNth > div:nth-of-type(2) > div:nth-of-type(3) {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        gap: 10px; /* Adjusted gap */
        text-align: center;
    }
    
    .landingPageFooter > .thirdNth > div:nth-of-type(2) > div:nth-of-type(3) > p:hover {
        color: rgb(255,182,44);
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }
    
    .landingPageFooter > .fourthNth {
        width: 100%;
        min-height: 3vh; /* Adjusted height */
        background-color: white;
        margin-top: 20px; /* Adjusted margin */
        display: flex;
        flex-direction: column;
        align-items: center;
        color: black;
        justify-content: space-between;
        padding: 5px 20px; /* Adjusted padding */
        gap: 5px;
    }
    
    .landingPageFooter > .fourthNth > div {
        display: flex;
        align-items: center;
        gap: 10px; /* Adjusted gap */
        font-size: x-small; /* Adjusted font size */
    }
    
    .landingPageFooter > .fourthNth > div > p:hover {
        transition: all 0.3s ease-in-out;
        color: rgb(2,42,78);
        cursor: pointer;
    }
}



/* Tablets: screens with a width between 600px and 1024px */
@media (min-width: 600px) and (max-width: 1024px) {
    /* CSS styles for tablets go here */

    .landingPageFooter {
        width: 100%;
        background-color: rgb(2,42,78);
        padding: 50px 20px 0 20px; /* Adjusted padding */
        display: flex;
        flex-direction: column;
        color: white;
    }
    
    .landingPageFooter > h2 {
        margin: 0 auto;
        text-align: center;
    }
    
    .landingPageFooter > span {
        margin: 10px auto; /* Adjusted margin */
        min-width: 50px; /* Adjusted min-width */
        height: 4px; /* Adjusted height */
        background-color: rgb(255,182,44);
    }
    
    .landingPageFooter > .firstNth {
        width: 90%; /* Adjusted width */
        margin: 0 auto;
        display: flex;
        align-items: center;
    }
    
    .landingPageFooter > .firstNth > input {
        display: flex;
        flex-grow: 1;
        background-color: transparent;
        border: none;
        padding: 5px; /* Adjusted padding */
        transition: all 0.3s ease-in-out;
        color: white;
    }
    
    .landingPageFooter > .firstNth > .rightIcon {
        font-size: 40px; /* Adjusted font size */
        cursor: pointer;
    }
    
    .landingPageFooter > .secondNth {
        width: 100%; /* Adjusted width */
        display: flex;
        margin: 40px auto; /* Adjusted margin */
        align-items: center;
    }
    
    .landingPageFooter > .secondNth > div:nth-of-type(1) {
        flex: 2;
        height: 3vh; /* Adjusted height */
        display: flex;
        background-image: repeating-linear-gradient(45deg, transparent, transparent 5px, lightgray 5px, lightgray 10px);
        background-size: 10px 10px;
        opacity: .5;
    }
    
    .landingPageFooter > .secondNth > div:nth-of-type(2) {
        flex: 4;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px; /* Adjusted gap */
    }
    
    .landingPageFooter > .secondNth > div:nth-of-type(2) > span {
        color: rgb(2,42,78);
        background-color: white;
        padding: 3px;
        border-radius: 50%; /* Adjusted border-radius */
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }
    
    .landingPageFooter > .secondNth > div:nth-of-type(3) {
        flex: 2;
        height: 3vh; /* Adjusted height */
        display: flex;
        background-image: repeating-linear-gradient(45deg, transparent, transparent 5px, lightgray 5px, lightgray 10px);
        background-size: 10px 10px;
        opacity: .5;
    }
    
    .landingPageFooter > .thirdNth {
        width: 100%; /* Adjusted width */
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }
    
    .landingPageFooter > .thirdNth > div:nth-of-type(1) {
        display: flex;
        flex: 1;
        gap: 10px; /* Adjusted gap */
        align-items: center;
        margin: 20px auto;
        padding: 20px 0;
        border-bottom: 1px solid rgba(169, 169, 169, 0.5); 
        border-top: 1px solid rgba(169, 169, 169, 0.5); 
    }
    
    .landingPageFooter > .thirdNth > div:nth-of-type(1) > div:nth-of-type(1) {
        display: flex;
        flex: 1;
    }
    
    .landingPageFooter > .thirdNth > div:nth-of-type(1) > div:nth-of-type(1) > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .landingPageFooter > .thirdNth > div:nth-of-type(1) > div:nth-of-type(2) {
        display: flex;
        flex: 3;
        flex-direction: column;
        align-items: flex-start;
        border-left: 1px solid rgba(169, 169, 169, 0.5); 
    }
    
    .landingPageFooter > .thirdNth > div:nth-of-type(1) > div:nth-of-type(2) > p {
        width: 90%; /* Adjusted width */
    }
    
    .landingPageFooter > .thirdNth > div:nth-of-type(1) > div:nth-of-type(2) > button {
        margin-top: 10px; /* Adjusted margin */
        width: fit-content;
        background-color: rgb(0,100,159);
        color: white;
        transition: all 0.3s ease-in-out;
        text-transform: inherit;
        padding: 3px 10px; /* Adjusted padding */
    }
    
    .landingPageFooter > .thirdNth > div:nth-of-type(1) > div:nth-of-type(2) > button:hover {
        background-color: rgb(255,182,44);
    }
    
    .landingPageFooter > .thirdNth > div:nth-of-type(2) {
        display: flex;
        flex-wrap: wrap;
        gap: 5%;
        gap: 20px;
        flex: 1;
        margin: 20px auto;
    }
    
    .landingPageFooter > .thirdNth > div:nth-of-type(2) > div:nth-of-type(1) {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        gap: 10px; /* Adjusted gap */
    }
    
    .landingPageFooter > .thirdNth > div:nth-of-type(2) > div:nth-of-type(1) > p:hover {
        color: rgb(255,182,44);
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }
    
    .landingPageFooter > .thirdNth > div:nth-of-type(2) > div:nth-of-type(2) {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        gap: 10px; /* Adjusted gap */
        align-items: center;
        text-align: center;
    }
    
    .landingPageFooter > .thirdNth > div:nth-of-type(2) > div:nth-of-type(2) > p:hover {
        color: rgb(255,182,44);
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }
    
    .landingPageFooter > .thirdNth > div:nth-of-type(2) > div:nth-of-type(3) {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        gap: 10px; /* Adjusted gap */
        text-align: center;
    }
    
    .landingPageFooter > .thirdNth > div:nth-of-type(2) > div:nth-of-type(3) > p:hover {
        color: rgb(255,182,44);
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }
    
    .landingPageFooter > .fourthNth {
        width: 100%;
        min-height: 3vh; /* Adjusted height */
        background-color: white;
        margin-top: 20px; /* Adjusted margin */
        display: flex;
        flex-direction: column;
        align-items: center;
        color: black;
        justify-content: space-between;
        padding: 5px 20px; /* Adjusted padding */
        gap: 5px;
    }
    
    .landingPageFooter > .fourthNth > div {
        display: flex;
        align-items: center;
        gap: 10px; /* Adjusted gap */
        font-size: x-small; /* Adjusted font size */
    }
    
    .landingPageFooter > .fourthNth > div > p:hover {
        transition: all 0.3s ease-in-out;
        color: rgb(2,42,78);
        cursor: pointer;
    }
  }
  