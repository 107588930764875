.teamForm {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
}

.teamForm > form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin: auto;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    background-color: white;
    padding: 50px;
}


.teamForm > form > div {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.teamForm > form > div > input {
    outline: none;
    border: 1px solid rgba(211, 211, 211, 0.5);
}


.teamForm > form > button {
    background-color: rgb(255,182,44);
    color: white;
    padding: 10px 20px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    border: 1px solid rgb(255,182,44);
}


.teamForm > form > button:hover {
    background-color: white;
    color: rgb(255,182,44);
}


.teamShowDash {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.teamShowDash > div {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 50%;
    background-color: white;
    margin: 50px auto;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 50px;
}



@media (max-width: 575.98px) {
    .teamForm {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    }

    .teamForm > form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        align-items: center;
        margin: auto;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        background-color: red;
        padding: 50px;
    }


    .teamForm > form > div {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .teamForm > form > div > input {
        outline: none;
        border: 1px solid rgba(211, 211, 211, 0.5);
    }


    .teamForm > form > button {
        background-color: rgb(255,182,44);
        color: white;
        padding: 10px 20px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        border: 1px solid rgb(255,182,44);
    }


    .teamForm > form > button:hover {
        background-color: white;
        color: rgb(255,182,44);
    }


    .teamShowDash {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .teamShowDash > div {
        display: flex;
        flex-direction: column;
        gap: 50px;
        width: 50%;
        background-color: white;
        margin: 50px auto;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        padding: 50px;
    }
}
