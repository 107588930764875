.landingPageAction {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.landingPageAction > div {
    display: flex;
    width: 60%;
    gap: 20px;
    margin: 100px auto 50px auto;
}


.landingPageAction > div:nth-of-type(n) {
    display: flex;
}

.landingPageAction > div:nth-of-type(n) > div > .huh {
    width: 80%;
    height: 40vh;
    margin: 50px 0 auto auto;
}

.landingPageAction > div:nth-of-type(2n) {
    display: flex;
    flex-direction: row-reverse;
}

.landingPageAction > div:nth-of-type(2n) > div > .huh {
    width: 80%;
    height: 40vh;
    margin: 100px auto auto 0;
}


.landingPageAction > div > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
}





.landingPageAction > div > div > div > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}



@media (max-width: 575.98px) {
    .landingPageAction {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    
    .landingPageAction > div {
        display: flex;
        width: 90%;
        gap: 20px;
        margin: 50px auto 50px auto;
        flex-direction: column-reverse;
    }
    
    
    .landingPageAction > div:nth-of-type(n) {
        display: flex;
    }
    
    .landingPageAction > div:nth-of-type(n) > div > .huh {
        width: 90%;
        height: 20vh;
        margin: auto;
    }
    
    .landingPageAction > div:nth-of-type(2n) {
        display: flex;
        flex-direction: column-reverse;
    }
    
    .landingPageAction > div:nth-of-type(2n) > div > .huh {
        width: 90%;
        height: 20vh;
        margin: auto;
    }
    
    
    .landingPageAction > div > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    
    
    
    
    
    .landingPageAction > div > div > div > img {
        width: auto;
        margin: auto;
        height: 100%;
        object-fit: cover;
    }
}


@media (min-width: 600px) and (max-width: 1024px) {
    /* CSS styles for tablets go here */
    .landingPageAction {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    
    .landingPageAction > div {
        display: flex;
        width: 90%;
        gap: 20px;
        margin: 100px auto 50px auto;
        flex-direction: column-reverse;
    }
    
    
    .landingPageAction > div:nth-of-type(n) {
        display: flex;
    }
    
    .landingPageAction > div:nth-of-type(n) > div > .huh {
        width: 40%;
        height: 20vh;
        margin: auto;
    }
    
    .landingPageAction > div:nth-of-type(2n) {
        display: flex;
        flex-direction: column-reverse;
    }
    
    .landingPageAction > div:nth-of-type(2n) > div > .huh {
        width: 40%;
        min-height: 20vh;
        margin: auto;
    }
    
    
    .landingPageAction > div > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    
    
    
    
    
    .landingPageAction > div > div > div > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
  }