/* Default styles for all screen sizes */
body {
    font-size: 16px;
  }

  .landingTopNavbarContainer {
    background-color: white;
    color: rgb(2,42,78);
    min-height: 5vh;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgb(2,42,78, .2);
 }
 
 .landingTopNavbarContainer > .landingLinks {
     margin-left: auto;
     margin-right: 200px;
     padding: 0;
     display: flex;
     align-items: center;
     list-style: none;
     gap: 10px;
 }
 
 .landingTopNavbarContainer > .landingLinks > li {
     display: flex;
     align-items: center;
     transition: all 0.3s ease-in-out;
     cursor: pointer;
     font-size: small;
     color: rgb(2,42,78);
     font-weight: 800;
     cursor: pointer;
     transition: all 0.3s ease-in-out;
 }
 
 
 .landingTopNavbarContainer > .landingLinks > li:hover {
     color: rgb(247,184,26);
 }
 
 .landingTopNavbarContainer > .landingLinks > li:nth-of-type(8) {
     background-color: rgb(0,100,159);
     height: 5vh;
     padding: 0 20px;
 }
 
 .landingTopNavbarContainer > .landingLinks > li:nth-of-type(8):hover {
     background-color: rgb(255,158,27);
 }



  
/* Styles for screens smaller than 576px (XS) */
@media (max-width: 575.98px) {
    body {
        font-size: 14px;
    }


    .landingTopNavbarContainer {
        background-color: white;
        color: white;
        min-height: 5vh;
        display: none;
        align-items: center;
        padding: 5px 10px;
     }
     
     .landingTopNavbarContainer > .landingLinks {
         margin-left: auto;
         margin-right: 0px;
         padding: 0;
         display: flex;
         align-items: center;
         list-style: none;
         gap: 10px;
     }
     
     .landingTopNavbarContainer > .landingLinks > li {
         display: flex;
         align-items: center;
         transition: all 0.3s ease-in-out;
         cursor: pointer;
         font-size: small;
     }
     
     
     
     .landingTopNavbarContainer > .landingLinks > li:nth-of-type(8) {
         background-color: rgb(0,100,159);
         height: 5vh;
         padding: 0 20px;
     }
     
     .landingTopNavbarContainer > .landingLinks > li:nth-of-type(8):hover {
         background-color: rgb(255,158,27);
     }
}

/* Styles for screens between 576px (SM) and 991.98px (MD) */
@media (min-width: 576px) and (max-width: 991.98px) {
    body {
        font-size: 18px;
    }

    .landingTopNavbarContainer {
        background-color: white;
        color: white;
        min-height: 5vh;
        display: flex;
        align-items: center;
        padding: 5px 10px;
     }
     
     .landingTopNavbarContainer > .landingLinks {
         margin-left: auto;
         margin-right: 0px;
         padding: 0;
         display: flex;
         align-items: center;
         list-style: none;
         gap: 10px;
     }
     
     .landingTopNavbarContainer > .landingLinks > li {
         display: flex;
         align-items: center;
         transition: all 0.3s ease-in-out;
         cursor: pointer;
         font-size: small;
     }
     

     
     .landingTopNavbarContainer > .landingLinks > li:nth-of-type(8) {
         background-color: white;
         height: 5vh;
         padding: 0 20px;
     }
     
     .landingTopNavbarContainer > .landingLinks > li:nth-of-type(8):hover {
         background-color: rgb(255,158,27);
     }
}

/* Styles for screens larger than 1200px (XL) */
@media (min-width: 1200px) {
    body {
        font-size: 20px;
    }

    .landingTopNavbarContainer {
        background-color: white;
        color: white;
        min-height: 5vh;
        display: flex;
        align-items: center;
     }
     
     .landingTopNavbarContainer > .landingLinks {
         margin-left: auto;
         margin-right: 200px;
         padding: 0;
         display: flex;
         align-items: center;
         list-style: none;
         gap: 10px;
     }
     
     .landingTopNavbarContainer > .landingLinks > li {
         display: flex;
         align-items: center;
         transition: all 0.3s ease-in-out;
         cursor: pointer;
         font-size: small;
     }
     
     

     .landingTopNavbarContainer > .landingLinks > li:nth-of-type(8) {
         background-color: white;
         height: 5vh;
         padding: 0 20px;
     }
     
     .landingTopNavbarContainer > .landingLinks > li:nth-of-type(8):hover {
         background-color: rgb(255,158,27);
     }
    
}