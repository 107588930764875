.slider-container {
  width: 100%;
  background-image: url('../images/world.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.slider {
  width: 100%;
  background-color: rgba(250, 183, 60, 0.863);
  display: flex;
  flex-direction: column
}

.sliderTopper {
  width: 100%;
  display: flex;
}

.sliderTopperLeft {
  flex: 1;
  padding: 100px;
}

.sliderTopperLeft > h1 {
  color: rgb(0,55,103);
  /* font-weight: 800;
  font-size: 60px; */
}

.sliderTopperRight {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sliderTopperRight > img {
  width: 500px;
  height: auto;
}

.sliderTopperRight > h2 {
  color: rgb(0,55,103);
  text-transform: uppercase;
  border-top: 1px solid rgba(0,55,103, .2);
  border-bottom: 2px solid rgba(0,55,103, .2);
  padding: 20px 0;
}


.sliderBottomer {
  width: 100%;
  background-color: rgb(0,58,112);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 50px 0;
}

.sliderBottomer > div {
  color: white;
  max-width: 70%;
  display: flex;
  flex-direction: column;
  border: 4px solid white;
  padding: 20px;
  gap: 20px;
}

.sliderBottomer > div > h1 {
  text-align: center;
}




/* Styles for screens smaller than 576px (XS) */
@media (max-width: 1024px) {
  .slider-container {
    width: 100%;
    background-image: url('../images/world.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  
  .slider {
    width: 100%;
    background-color: rgba(250, 183, 60, 0.863);
    display: flex;
    flex-direction: column
  }
  
  .sliderTopper {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .sliderTopperLeft {
    flex: 1;
    padding: 20px 10px;
  }
  
  .sliderTopperLeft > h1 {
    color: rgb(0,55,103);
    /* font-weight: 800;
    font-size: 30px; */
  }
  
  .sliderTopperRight {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .sliderTopperRight > img {
    width: 80%;
    height: auto;
  }
  
  .sliderTopperRight > h2 {
    color: rgb(0,55,103);
    text-transform: uppercase;
    border-top: 1px solid rgba(0,55,103, .2);
    border-bottom: 2px solid rgba(0,55,103, .2);
    padding: 10px 0;
    margin-bottom: 20px;
  }
  
  
  .sliderBottomer > div {
    color: white;
    max-width: 98%;
    display: flex;
    flex-direction: column;
    border: 4px solid white;
    padding: 10px;
    gap: 10px;
  }
  
  /* Keyframes animation for background fill */
  @keyframes fillBackground {
    to {
      background-size: 100% 100%; /* Fill background from 0% to 100% */
    }
  }
}

