.landingPageInterfaith{
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 80vh;
   
}


.landingPageBannerDonateButton {
    border: 1px solid rgb(255,182,44);
    background-color: rgb(255,182,44);
    color: white;
    margin: 50px auto 0 auto;
    transition: all 0.3s ease-in-out;
}


.landingPageBannerDonateButton:hover {
    background-color: white;
    color: rgb(255,182,44);
}
.landingPageInterfaith> div {
    display: flex;
    width: 100%;
    gap: 20px;
    margin: 70px auto 0 auto;
}

.landingPageInterfaith > .one {
    height: 60vh;
    width: 100%;
    margin: 0;
    
}

.landingPageInterfaith > .two {
    width: 90%;
    margin: 50px auto 20px auto;
}

.landingPageInterfaith > .two > div > h1 {
    text-align: center;
}

.landingPageInterfaith> div:nth-of-type(3) {
    margin: 20px auto 0px;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;

   
}

.landingPageInterfaith> div:nth-of-type(3) > div {
    margin: 0;
    /* padding: 20px; */
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    
}
.landingPageInterfaith> div:nth-of-type(3) > div >  div { 
    height: 55vh;
    width: 50%;
    
}
.landingPageInterfaith> div:nth-of-type(3) > div >  div > video {
    width: 100%;
    height: 100%;
    object-fit: fill;
}



.landingPageInterfaith > .one > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}


.landingPageInterfaith> div > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.landingPageInterfaith> div > div > button {
    background-color: rgb(245,143,30);
    color: white;
    padding: 5px 0;
    margin: auto;
    border: 1px solid rgb(245,143,30);
    position: relative;
}


.landingPageInterfaith> div > div > button:hover {
    background-color: white;
    color: rgb(245,143,30);
}


.landingPageInterfaith> div > div > .locTime {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 10%;
    gap: 20px;
}

.landingPageInterfaith> div > div > .locTime > div {
    width: 45%;
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(211, 211, 211, 0.8);
    gap: 10px;
    padding: 10px;
}

.landingPageInterfaith> div > div > .locTime > div > h4 {
    color: rgb(44, 42, 42);
}

.landingPageInterfaith> div > div > .locTime > div > p {
    font-size: small;
    color: rgb(82, 79, 79);
}

.huh2Parent {
    display: flex;
    flex-direction: column;
}

.newberryInterfaithSign {
    width: 98%;
    min-height: 0vh;
    object-fit: cover;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}

/* .newberryInterfaithSign > img {
    width: 100%;
    height: auto;
    border-radius: 10px;
} */

.huh2 {
    display: flex;
    flex-wrap: wrap;
    gap: 10%;
    gap: 20px;
    padding: 10px;
}


.huh2 > div {
    width: 98%;
    min-height: 40vh;
    object-fit: cover;
    margin: auto;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.huh2 > div > img {
    width: 100%;
    min-height: 20vh;
    object-fit: cover;
    border-radius: 10px;
}

.huh2 > div > video {
    width: 100%;
    height: 50vh;
    object-fit: fill;
    border-radius: 10px;
}








.landingPageInterfaith> .child {
    flex: 1;
    padding: 20px;
    display: flex;
    gap: 20px;
    padding: 0 20px 200px 20px;
}

.landingPageInterfaith> .child > button {
    background-color: rgb(0,100,159);
    color: white;
    transition: all 0.3s ease-in-out;
    text-transform: inherit;
    width: fit-content;
    margin-left: auto;
}


.landingPageInterfaith> .child > button:hover {
    background-color: rgb(255,182,44);
}

.landingPageInterfaith> .child > div {
    flex: 1;
    display: flex;
    gap: 20px;
    padding: 20px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background-color: rgb(249,250,251);
}



.landingPageInterfaith> .child > div:last-of-type {
    border-bottom: none;
}


.landingPageInterfaith> .child > div > div:nth-of-type(1) {
    flex: 1;
    display: flex;
    height: 20vh;
}

.landingPageInterfaith> .child > div > div:nth-of-type(1) > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.landingPageInterfaith> .child > div > div:nth-of-type(2) {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.landingPageInterfaith> .child > div > div:nth-of-type(2) > h4 {
    color: rgb(0,58,112);
}

.landingPageInterfaith> .child > div > div:nth-of-type(2) > h3:hover {
    text-decoration: underline;
    color: rgb(0,58,112);
    transition: all 0.3s ease-in-out;
}

.blenderCardHolder {
    width: 100%;
}



@media (max-width: 1024px) {
    .landingPageInterfaith{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 10px auto;
    }

    .landingPageInterfaith> div:nth-of-type(3) > div {
        margin: 0;
        padding: 10px;
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    .landingPageInterfaith> div:nth-of-type(3) > div >  div { 
        height: 50vh;
        width: 100%;
    }
    .landingPageInterfaith> div {
        display: flex;
        flex-direction: column;
        width: 90%;
        gap: 10px;
        margin: 10px auto 10px auto;
    }
    
    
    .landingPageInterfaith> div > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .landingPageInterfaith> div > div .huh2 {
        width: 100%;
        min-height: 20vh;
        margin: 10px auto;
    }
    

    
    .landingPageInterfaith> div > div > div > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    
    
    
    
    
    .landingPageInterfaith> .child {
        flex: 1;
        padding: 10px;
        display: flex;
        gap: 10px;
        padding: 0 0px 10px 0px;
    }


    .landingPageInterfaith> .child > video {
        width: 100%;
        height: auto;
    }

    
    .landingPageInterfaith> .child > button {
        background-color: rgb(0,100,159);
        color: white;
        transition: all 0.3s ease-in-out;
        text-transform: inherit;
        width: fit-content;
        margin-left: auto;
    }
    
    
    .landingPageInterfaith> .child > button:hover {
        background-color: rgb(255,182,44);
    }
    
    .landingPageInterfaith> .child > div {
        flex: 1;
        display: flex;
        gap: 10px;
        padding: 10px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        background-color: rgb(249,250,251);
    }
    
    
    
    .landingPageInterfaith> .child > div:last-of-type {
        border-bottom: none;
    }
    
    
    .landingPageInterfaith> .child > div > div:nth-of-type(1) {
        flex: 1;
        display: flex;
        height: 20vh;
    }
    
    .landingPageInterfaith> .child > div > div:nth-of-type(1) > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    
    .landingPageInterfaith> .child > div > div:nth-of-type(2) {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    
    
    .landingPageInterfaith> .child > div > div:nth-of-type(2) > h4 {
        color: rgb(0,58,112);
    }
    
    .landingPageInterfaith> .child > div > div:nth-of-type(2) > h3:hover {
        text-decoration: underline;
        color: rgb(0,58,112);
        transition: all 0.3s ease-in-out;
    }
}