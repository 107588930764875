

.usda-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background: rgba(255, 255, 255, 0.9); 
  border-radius: 10px;
}

.usda-header {
  text-align: center;
  margin-bottom: 40px;
}

.usda-header h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #002d72;
}

.usda-header-intro {
  font-size: 1.4rem;
  color: #555;
  font-style: italic;
  margin-top: 10px;
}

.usda-video-highlight {
  position: relative;
  width: 100%; 
  height: 0;
  padding-bottom: 56.25%; 
  overflow: hidden;
}

.usda-video-highlight > video {
  position: absolute;
  top: 50%; 
  left: 50%;
  transform: translate(-50%, -50%); 
  width: 100%;
  height: auto;
  object-fit: cover; 
}


.usda-header-description {
  font-size: 1.1rem;
  color: #333;
  margin: 20px 0;
  line-height: 1.7;
}

.usda-services,
.usda-target-audience,
.usda-upcoming-events,
.usda-resources,
.usda-media-spotlight {
  margin-bottom: 40px;
  background: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.usda-services h2,
.usda-target-audience h2,
.usda-upcoming-events h2,
.usda-resources h2,
.usda-media-spotlight h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #002d72;
  padding-bottom: 10px;
}

.usda-services-list,
.usda-audience-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 0;
  margin: 20px 0;
}

.usda-services-list li,
.usda-audience-list li {
  font-size: 1.1rem;
  margin-bottom: 10px;
  list-style: none;
  padding-left: 20px;
  position: relative;
}

.usda-services-list li::before,
.usda-audience-list li::before {
  content: '•';
  color: #d9534f;
  position: absolute;
  left: 0;
  font-size: 1.2rem;
}

.usda-countdown-banner {
  background-color: #002d72;
  color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.countdown-animation {
  font-size: 1.4rem;
  font-weight: bold;
}

.usda-media-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.usda-media-gallery img {
  width: 100%;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.usda-media-gallery img:hover {
  transform: scale(1.01);
}

/* Utility Classes */
.text-center {
  text-align: center;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 768px) {
  .usda-services-list,
  .usda-audience-list {
    grid-template-columns: 1fr;
  }
}
