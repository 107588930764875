.landingPageWhatWeDo {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.landingPageWhatWeDo > h2 {
    margin: 20px auto 0 auto;

}

.landingPageWhatWeDo > div:nth-of-type(1) {
    width: 100%;
    margin-top: 50px;
    display: flex;
    
}

.landingPageWhatWeDo > div:nth-of-type(1) > video {
    width: 30%;
    height: auto;
    object-fit: cover;
    margin: auto;
}

.two > h2 {
    text-align: center;
}



.landingPageWhatWeDo > div:nth-of-type(2) {
    width: 60%;
    display: flex;
    margin: 10px auto;
}

.landingPageWhatWeDo > div:nth-of-type(3) {
    width: 60%;
    display: flex;
    flex-direction: column;
    margin: 0px auto 20px auto;
    gap: 20px;
}

.landingPageWhatWeDo > div:nth-of-type(3) > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 2%;
}

.landingPageWhatWeDo > div:nth-of-type(3) > div > div {
    flex: 0 0 calc(50% - 20px); /* Adjusted width for 3 columns with 20px gap */
    margin-bottom: 20px; /* Add some margin to create a gap between rows */
    height: 30vh;
    position: relative;

}

.landingPageWhatWeDo > div:nth-of-type(3) > div > div > img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Changed object-fit to cover for a better visual */
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}


.landingPageWhatWeDo > div:nth-of-type(3) > div > div > img:hover {
    transform: scale(1.01);
    
}


.landingPageWhatWeDo > div:nth-of-type(3) > div > div > h2 {
    background-color: rgb(2,42,78);
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 10px 0;
    font-size: smaller;
    
}


.landingPageWhatWeDo > div:nth-of-type(4) {
    width: 60%;
    display: flex;
    flex-direction: column;
    margin: 0px auto 10px auto;
    
}

.three {
    background-color: white !important;
}


.three > h2 {
    text-align: center;
}

@media (max-width: 575.98px) {
    .landingPageWhatWeDo {
        display: flex;
        width: 100%;
        flex-direction: column;
    }
    
    .landingPageWhatWeDo > div:nth-of-type(1) {
        width: 100%;
        margin-top: 10px;
        display: flex;

    }
    
    .landingPageWhatWeDo > div:nth-of-type(1) > video {
        width: 90%;
        height: 40vh;
        object-fit: cover;
        margin: auto;
    }
    
    
    .landingPageWhatWeDo > div:nth-of-type(2) {
        width: 90%;
        display: flex;
        flex-direction: column;
        margin: 10px auto;
    }
    
    .landingPageWhatWeDo > div:nth-of-type(3) {
        width: 90%;
        display: flex;
        flex-direction: column;
        margin: 0px auto 10px auto;
        gap: 10px;
        
    }
    
    .landingPageWhatWeDo > div:nth-of-type(3) > div {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 10px;
        
    }
    
    .landingPageWhatWeDo > div:nth-of-type(3) > div > div {
        flex: 1;
        height: 30vh;
        position: relative;
        
    }
    
    .landingPageWhatWeDo > div:nth-of-type(3) > div > div > img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

    }
    
    .landingPageWhatWeDo > div:nth-of-type(3) > div > div > img:hover {
        transform: scale(1.01);
        
    }
    
    
    .landingPageWhatWeDo > div:nth-of-type(3) > div > div > h2 {
        background-color: rgb(2,42,78);
        color: white;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        padding: 10px 0;
    }
    
    
    .landingPageWhatWeDo > div:nth-of-type(4) {
        width: 90%;
        display: flex;
        flex-direction: column;
        margin: 0px auto 10px auto;
        gap: 10px;
    }
}

.text-border{
    padding: 20px;
    border: 4px solid rgb(12,46,78) !important;
}

.landingPageWhatWeDo > div > .text-card{
    margin-top: 20px;
    margin-bottom: 20px;
}

/* Tablets: screens with a width between 600px and 1024px */
@media (min-width: 600px) and (max-width: 1024px) {
    .landingPageWhatWeDo {
        display: flex;
        width: 100%;
        flex-direction: column;
    }
    
    .landingPageWhatWeDo > div:nth-of-type(1) {
        width: 100%;
        margin-top: 50px;
        display: flex;
    }
    
    .landingPageWhatWeDo > div:nth-of-type(1) > video {
        width: 80%;
        height: 60vh;
        object-fit: cover;
        margin: auto;
    }
    
    
    
    .landingPageWhatWeDo > div:nth-of-type(2) {
        width: 90%;
        display: flex;
        flex-direction: column;
        margin: 100px auto;
    }
    
    .landingPageWhatWeDo > div:nth-of-type(3) {
        width: 90%;
        display: flex;
        flex-direction: column;
        margin: 0px auto 100px auto;
        gap: 20px;
    }
    
    .landingPageWhatWeDo > div:nth-of-type(3) > div {
        display: flex;
        flex-wrap: wrap;
        gap: 4%;
        justify-items: flex-start;
    }
    
    .landingPageWhatWeDo > div:nth-of-type(3) > div > div {
        width: 48%; /* Adjusted width for 2 columns with 20px gap */
        margin-bottom: 20px; /* Add some margin to create a gap between rows */
        height: 25vh;
        position: relative;
    }
    
    .landingPageWhatWeDo > div:nth-of-type(3) > div > div > img {
        width: 100%;
        height: 100%;
        object-fit: cover; /* Changed object-fit to cover for a better visual */
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }
    
    .landingPageWhatWeDo > div:nth-of-type(3) > div > div > img:hover {
        transform: scale(1.01);
    }
    
    
    .landingPageWhatWeDo > div:nth-of-type(3) > div > div > h2 {
        background-color: rgb(2,42,78);
        color: white;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        padding: 10px 0;
    }
    
    
    .landingPageWhatWeDo > div:nth-of-type(4) {
        width: 90%;
        display: flex;
        flex-direction: column;
        margin: 0px auto 100px auto;
        gap: 20px;
    }
}