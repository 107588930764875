.landingPageDivision {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.landingPageDivision > div {
    display: flex;
    width: 60%;
    gap: 20px;
    margin: 100px auto 50px auto;
}


.landingPageDivision > div > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.landingPageDivision> div > div > button {
    background-color: rgb(245,143,30);
    color: white;
    padding: 5px 0;
    margin-right: auto;
    border: 1px solid rgb(245,143,30);
}


.landingPageDivision> div > div > button:hover {
    background-color: white;
    color: rgb(245,143,30);
}


.huh {
    width: 80%;
    height: 40vh;
    margin: auto 0 auto auto;
}

.landingPageDivision > div > div > div > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}






.landingPageDivision > .child {
    flex: 1;
    padding: 20px;
    display: flex;
    gap: 20px;
    padding: 0 20px 200px 20px;
}

.landingPageDivision > .child > button {
    background-color: rgb(2,42,78);
    color: white;
    transition: all 0.3s ease-in-out;
    text-transform: inherit;
    width: fit-content;
    margin-left: auto;
}


.landingPageDivision > .child > button:hover {
    background-color: rgb(255,182,44);
}

.landingPageDivision > .child > div {
    flex: 1;
    display: flex;
    gap: 20px;
    padding: 20px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background-color: rgb(249,250,251);
}



.landingPageDivision > .child > div:last-of-type {
    border-bottom: none;
}


.landingPageDivision > .child > div > div:nth-of-type(1) {
    flex: 1;
    display: flex;
    height: 20vh;
}

.landingPageDivision > .child > div > div:nth-of-type(1) > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.landingPageDivision > .child > div > div:nth-of-type(2) {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.landingPageDivision > .child > div > div:nth-of-type(2) > h4 {
    color: rgb(0,58,112);
}

.landingPageDivision > .child > div > div:nth-of-type(2) > h3:hover {
    text-decoration: underline;
    color: rgb(0,58,112);
    transition: all 0.3s ease-in-out;
}



@media (max-width: 1024px) {
    .landingPageDivision {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 50px auto;
    }
    
    .landingPageDivision > div {
        display: flex;
        flex-direction: column;
        width: 90%;
        gap: 20px;
        margin: 100px auto 50px auto;
    }
    
    
    .landingPageDivision > div > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .landingPageDivision > div > div .huh {
        width: 100%;
        height: 20vh;
        margin: 20px auto;
    }
    

    
    .landingPageDivision > div > div > div > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    
    
    
    
    
    .landingPageDivision > .child {
        flex: 1;
        padding: 20px;
        display: flex;
        gap: 20px;
        padding: 0 0px 100px 0px;
    }
    
    .landingPageDivision > .child > button {
        background-color: rgb(2,42,78);
        color: white;
        transition: all 0.3s ease-in-out;
        text-transform: inherit;
        width: fit-content;
        margin-left: auto;
    }
    
    
    .landingPageDivision > .child > button:hover {
        background-color: rgb(255,182,44);
    }
    
    .landingPageDivision > .child > div {
        flex: 1;
        display: flex;
        gap: 20px;
        padding: 20px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        background-color: rgb(249,250,251);
    }
    
    
    
    .landingPageDivision > .child > div:last-of-type {
        border-bottom: none;
    }
    
    
    .landingPageDivision > .child > div > div:nth-of-type(1) {
        flex: 1;
        display: flex;
        height: 20vh;
    }
    
    .landingPageDivision > .child > div > div:nth-of-type(1) > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    
    .landingPageDivision > .child > div > div:nth-of-type(2) {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    
    
    .landingPageDivision > .child > div > div:nth-of-type(2) > h4 {
        color: rgb(0,58,112);
    }
    
    .landingPageDivision > .child > div > div:nth-of-type(2) > h3:hover {
        text-decoration: underline;
        color: rgb(0,58,112);
        transition: all 0.3s ease-in-out;
    }
}