.landingPageResearchDivision{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.landingPageResearchDivision> div {
    display: flex;
    width: 60%;
    gap: 20px;
    margin: 20px auto 50px auto;
}


.landingPageResearchDivision> div > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.landingPageResearchDivision> div > div > button {
    background-color: rgb(245,143,30);
    color: white;
    padding: 5px 0;
    margin-right: auto;
    border: 1px solid rgb(245,143,30);
}


.landingPageResearchDivision> div > div > button:hover {
    background-color: white;
    color: rgb(245,143,30);
}


.landingPageResearchDivision> div > div > .locTime {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 10%;
    gap: 20px;
}

.landingPageResearchDivision> div > div > .locTime > div {
    width: 45%;
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(211, 211, 211, 0.8);
    gap: 10px;
    padding: 10px;
}

.landingPageResearchDivision> div > div > .locTime > div > h4 {
    color: rgb(44, 42, 42);
}

.landingPageResearchDivision> div > div > .locTime > div > p {
    font-size: small;
    color: rgb(82, 79, 79);
}

.huh2Parent {
    display: flex;
    flex-direction: column;
}

.huh2 {
    display: flex;
    flex-wrap: wrap;
    gap: 10%;
    gap: 20px;
    padding: 10px;
}


.huh2 > img {
    width: 45%;
    height: 40vh;
    object-fit: cover;
    margin: auto;
    border-radius: 10px;
}







.landingPageResearchDivision> .researchActivities {
    flex: 1;
    width: 100%;
    display: flex;
    background: url('../../images/first-blog-image.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.landingPageResearchDivision> .researchActivities > .researchActivitiesCover {
    background-color: rgb(2,42,78);
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.researchActivitiesCoverCardHolder {
    width: 90%;
    margin: 50px auto;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
}

.researchActivitiesCoverCardHolder > div {
    width: calc(100% / 3);
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.researchActivitiesCoverCardHolder > div > img {
    width: 100%;
    height: 40vh;
    object-fit: cover;
}

.researchActivitiesCoverCardHolder > div:nth-of-type(odd) {
    display: flex;
    flex-direction: column;
}

.researchActivitiesCoverCardHolder > div:nth-of-type(odd) > h2 {
    display: flex;
    margin: 50px auto 10px 20px;
    border-bottom: 4px solid rgba(255,158,27, .8);
    padding-bottom: 10px;
    color: rgb(0,0,0);
}

.researchActivitiesCoverCardHolder > div:nth-of-type(odd) > p {
    display: flex;
    margin: 20px auto 10px 20px;
    justify-content: flex-start;
    text-align: start;
    font-weight: 400;
}



@media (max-width: 575.98px) {
    .landingPageResearchDivision> div > div > .locTime > div > p:nth-of-type(2) {
        font-size:xx-small;
        color: rgb(82, 79, 79);
        text-wrap: wrap;
    }
}







@media (max-width: 1024px) {
    .landingPageResearchDivision{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 10px auto;
    }
    
    .landingPageResearchDivision> div {
        display: flex;
        flex-direction: column;
        width: 98%;
        gap: 20px;
        margin: 10px auto 10px auto;
    }
    
    
    .landingPageResearchDivision> div > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .landingPageResearchDivision> div > div .huh2 {
        width: 100%;
        min-height: 20vh;
        margin: 20px auto;
    }
    

    
    .landingPageResearchDivision> div > div > div > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    
    
    
    
    
    
    .landingPageResearchDivision> .child {
        flex: 1;
        padding: 20px;
        display: flex;
        gap: 20px;
        padding: 0 0px 100px 0px;
    }
    
    .landingPageResearchDivision> .child > button {
        background-color: rgb(0,100,159);
        color: white;
        transition: all 0.3s ease-in-out;
        text-transform: inherit;
        width: fit-content;
        margin-left: auto;
    }
    
    
    .landingPageResearchDivision> .child > button:hover {
        background-color: rgb(255,182,44);
    }
    
    .landingPageResearchDivision> .child > div {
        flex: 1;
        display: flex;
        gap: 20px;
        padding: 20px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        background-color: rgb(249,250,251);
    }
    
    
    
    .landingPageResearchDivision> .child > div:last-of-type {
        border-bottom: none;
    }
    
    
    .landingPageResearchDivision> .child > div > div:nth-of-type(1) {
        flex: 1;
        display: flex;
        height: 20vh;
    }
    
    .landingPageResearchDivision> .child > div > div:nth-of-type(1) > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    
    .landingPageResearchDivision> .child > div > div:nth-of-type(2) {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    
    
    .landingPageResearchDivision> .child > div > div:nth-of-type(2) > h4 {
        color: rgb(0,58,112);
    }
    
    .landingPageResearchDivision> .child > div > div:nth-of-type(2) > h3:hover {
        text-decoration: underline;
        color: rgb(0,58,112);
        transition: all 0.3s ease-in-out;
    }







    .landingPageResearchDivision> .researchActivities {
        flex: 1;
        width: 100%;
        display: flex;
        background: url('../../images/first-blog-image.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    
    .landingPageResearchDivision> .researchActivities > .researchActivitiesCover {
        background-color: rgb(2,42,78);
        width: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: column;
    }
    
    .researchActivitiesCoverCardHolder {
        width: 90%;
        margin: 50px auto;
        background-color: white;
        display: flex;
        flex-wrap: wrap;
    }
    
    .researchActivitiesCoverCardHolder > div {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .researchActivitiesCoverCardHolder > div > img {
        width: 100%;
        height: 40vh;
        object-fit: cover;
    }
    
    .researchActivitiesCoverCardHolder > div:nth-of-type(odd) {
        display: flex;
        flex-direction: column;
    }
    
    .researchActivitiesCoverCardHolder > div:nth-of-type(odd) > h2 {
        display: flex;
        margin: 10px auto 10px auto;
        border-bottom: 4px solid rgba(255,158,27, .8);
        width: 98%;
        padding-bottom: 10px;
        color: rgb(0,0,0);
    }
    
    .researchActivitiesCoverCardHolder > div:nth-of-type(odd) > p {
        display: flex;
        margin: 20px auto 10px 20px;
        justify-content: flex-start;
        text-align: start;
        font-weight: 400;
    }

}