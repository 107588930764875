.landingPageEducationDivision{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.landingPageEducationDivision> div {
    display: flex;
    width: 60%;
    gap: 20px;
    margin: 10px auto 50px auto;
}


.landingPageEducationDivision> div > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.landingPageEducationDivision> div > div > button {
    background-color: rgb(245,143,30);
    color: white;
    padding: 5px 0;
    margin-right: auto;
    border: 1px solid rgb(245,143,30);
}


.landingPageEducationDivision> div > div > button:hover {
    background-color: white;
    color: rgb(245,143,30);
}


.landingPageEducationDivision> div > div > .locTime {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 10%;
    gap: 20px;
}

.landingPageEducationDivision> div > div > .locTime > div {
    width: 45%;
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(211, 211, 211, 0.8);
    gap: 10px;
    padding: 10px;
}

.landingPageEducationDivision> div > div > .locTime > div > h4 {
    color: rgb(44, 42, 42);
}

.landingPageEducationDivision> div > div > .locTime > div > p {
    font-size: small;
    color: rgb(82, 79, 79);
}

.huh2Parent {
    display: flex;
    flex-direction: column;
}

.huh4 {
    display: flex;
    flex-wrap: wrap;
}


.huh4 > img {
    width: 100%;
    height: 40vh;
    object-fit: cover;
    margin: auto;
    border-radius: 10px;
}







.landingPageEducationDivision> .educationActivities {
    flex: 1;
    width: 100%;
    display: flex;
    background: url('../../images/first-blog-image.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.landingPageEducationDivision> .educationActivities > .educationActivitiesCover {
    background-color: rgba(27,76,123, .6);
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.educationActivitiesCoverCardHolder {
    width: 90%;
    margin: 50px auto;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
}

.educationActivitiesCoverCardHolder > div {
    width: calc(100% / 3);
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.educationActivitiesCoverCardHolder > div > img {
    width: 100%;
    height: 40vh;
    object-fit: cover;
}

.educationActivitiesCoverCardHolder > div:nth-of-type(odd) {
    display: flex;
    flex-direction: column;
}

.educationActivitiesCoverCardHolder > div:nth-of-type(odd) > h2 {
    display: flex;
    margin: 50px auto 10px 20px;
    border-bottom: 4px solid rgba(255,158,27, .8);
    padding-bottom: 10px;
    color: rgb(0,0,0);
}

.educationActivitiesCoverCardHolder > div:nth-of-type(odd) > p {
    display: flex;
    margin: 20px auto 10px 20px;
    justify-content: flex-start;
    text-align: start;
    font-weight: 400;
}








@media (max-width: 1024px) {
    .landingPageEducationDivision{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 10px auto;
    }
    
    .landingPageEducationDivision> div {
        display: flex;
        flex-direction: column;
        width: 90%;
        gap: 10px;
        margin: 10px auto 10px auto;
    }
    
    
    .landingPageEducationDivision> div > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .landingPageEducationDivision> div > div .huh2 {
        width: 100%;
        min-height: 20vh;
        margin: 10px auto;
    }
    

    
    .landingPageEducationDivision> div > div > div > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    
    
    
    
    
    .landingPageEducationDivision> .child {
        flex: 1;
        padding: 10px;
        display: flex;
        gap: 10px;
        padding: 0 0px 10px 0px;
    }
    
    .landingPageEducationDivision> .child > button {
        background-color: rgb(0,100,159);
        color: white;
        transition: all 0.3s ease-in-out;
        text-transform: inherit;
        width: fit-content;
        margin-left: auto;
    }
    
    
    .landingPageEducationDivision> .child > button:hover {
        background-color: rgb(255,182,44);
    }
    
    .landingPageEducationDivision> .child > div {
        flex: 1;
        display: flex;
        gap: 10px;
        padding: 10px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        background-color: rgb(249,250,251);
    }
    
    
    
    .landingPageEducationDivision> .child > div:last-of-type {
        border-bottom: none;
    }
    
    
    .landingPageEducationDivision> .child > div > div:nth-of-type(1) {
        flex: 1;
        display: flex;
        height: 20vh;
    }
    
    .landingPageEducationDivision> .child > div > div:nth-of-type(1) > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    
    .landingPageEducationDivision> .child > div > div:nth-of-type(2) {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    
    
    .landingPageEducationDivision> .child > div > div:nth-of-type(2) > h4 {
        color: rgb(0,58,112);
    }
    
    .landingPageEducationDivision> .child > div > div:nth-of-type(2) > h3:hover {
        text-decoration: underline;
        color: rgb(0,58,112);
        transition: all 0.3s ease-in-out;
    }




    .landingPageEducationDivision> .educationActivities {
        flex: 1;
        width: 100%;
        display: flex;
        background: url('../../images/first-blog-image.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    
    .landingPageEducationDivision> .educationActivities > .educationActivitiesCover {
        background-color: rgba(27,76,123, .6);
        width: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: column;
    }
    
    .educationActivitiesCoverCardHolder {
        width: 98%;
        margin: 10px auto;
        background-color: white;
        display: flex;
        flex-wrap: wrap;
    }
    
    .educationActivitiesCoverCardHolder > div {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .educationActivitiesCoverCardHolder > div > img {
        width: 100%;
        height: 40vh;
        object-fit: cover;
    }
    
    .educationActivitiesCoverCardHolder > div:nth-of-type(odd) {
        display: flex;
        flex-direction: column;
    }
    
    .educationActivitiesCoverCardHolder > div:nth-of-type(odd) > h2 {
        display: flex;
        margin: 10px auto 10px auto;
        width: 98%;
        border-bottom: 4px solid rgba(255,158,27, .8);
        padding-bottom: 10px;
        color: rgb(0,0,0);
    }
    
    .educationActivitiesCoverCardHolder > div:nth-of-type(odd) > p {
        display: flex;
        margin: 20px auto 10px 20px;
        justify-content: flex-start;
        text-align: start;
        font-weight: 400;
    }
}