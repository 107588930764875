.landingPageWhereWeWorkMain {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.landingPageWhereWeWorkMain > .first {
    width: 100%;
    height: 60vh;
    margin: auto;
}

.landingPageWhereWeWorkMain > .first > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.landingPageWhereWeWorkMain > .second {
    width: 60%;
    display: flex;
    flex-direction: column;
    margin: 50px auto;
}

.landingPageWhereWeWorkMain > .second > h2 {
    text-align: center;
}

.text-border{
    border: 1px solid gray;
    padding: 20px;
}

.text-card{
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 20px;
}

.landingPageWhereWeWorkMain > h2 {
    margin: 0 auto 50px auto;
    border-bottom: black;
    padding-bottom: 10px;
    color: black;
}


.landingPageWhereWeWorkMain > .third {
    width: 60%;
    gap: 4%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto 100px auto;
}

.landingPageWhereWeWorkMain > .third > div {
    width: 22%;
    height: auto;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.landingPageWhereWeWorkMain > .third > div > img {
    width: 100%;
    height: auto;
    object-fit: fill;
}


@media (max-width: 575.98px) {
    .landingPageWhereWeWorkMain {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    
    .landingPageWhereWeWorkMain > .first {
        width: 100%;
        height: 40vh;
        margin: auto;
    }
    
    .landingPageWhereWeWorkMain > .first > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .landingPageWhereWeWorkMain > .second {
        width: 90%;
        display: flex;
        flex-direction: column;
        margin: 50px auto;
    }
    
    .landingPageWhereWeWorkMain > h2 {
        margin: 0 auto 50px auto;
        border-bottom: black;
        padding-bottom: 10px;
        color: black;
    }
    
    
    .landingPageWhereWeWorkMain > .third {
        width: 90%;
        gap: 4%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        background-color: rgb(250, 249, 247);
        margin: 0 auto 100px auto;
    }
    
    .landingPageWhereWeWorkMain > .third > div {
        width: 42%;
        height: auto;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }
    
    .landingPageWhereWeWorkMain > .third > div > img {
        width: 100%;
        height: auto;
        object-fit: fill;
    }
}